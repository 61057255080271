/**
 * 统一封装localstorage的操作，便于以后拦截,每个要set的key都要在这声明，避免冲突
 *
 */
export enum LOCALSTORAGE_KEYS {
  CHAIN_ID = 'chainId',
  SIGNATURE = 'signature',
  ACCOUNT_ADDRESS = 'account_account',
  SIGNATURE_TIMESTAMP = 'signature_timestamp',
  MANUE_LOGIN = 'manue_login',
}

function set(k: LOCALSTORAGE_KEYS, v: string) {
  if (!Object.values(LOCALSTORAGE_KEYS).includes(k)) {
    return console.error('请先在 LOCALSTORAGE_KEYS中进行声明');
  }
  window.localStorage.setItem(k, v);
}

function get(k: string) {
  return window.localStorage.getItem(k);
}

function clear() {
  return window.localStorage.clear();
}

function remove(k: string) {
  return window.localStorage.removeItem(k);
}

function has(k: string): boolean {
  return Boolean(window.localStorage.getItem(k));
}

export default {
  set,
  get,
  clear,
  remove,
  has,
};
