import { PageNameType, getServerBaseUrl } from "config/backend";
import { MDCA_DECIMALS, USD_DECIMALS } from 'lib/legacy';
import { bigNumberify, expandDecimals } from "lib/numbers";
import { useMemo } from 'react';
import useSWR from "swr";

export function usePositionInfo(chainId) {
  const domain = getServerBaseUrl(chainId, PageNameType.STATS)
  const url = `${domain}/api/position?chainId=${chainId}`;
  const { data = { longOpenInterest: '0', shortOpenInterest: '0', timestamp :'0'} } = useSWR(
    url,
    async (url) => {
      const res = await fetch(url);
      const json = await res.json();
      return json
    },
    {
      refreshInterval: 60000,
    }
  );

  const openInterest = useMemo(() => {
    if (!data) { 
      return '0';
    }
    const { longOpenInterest, shortOpenInterest } = data;
    if (!longOpenInterest || !shortOpenInterest) {
      return '0';
    }
    const res = bigNumberify(longOpenInterest)?.add(shortOpenInterest);
    return res;
   }, [data])
  return {
    ...data,
    openInterest
  };
}