import { Trans, t } from "@lingui/macro";
import Tooltip from "components/Tooltip/Tooltip";
import { BASIS_POINTS_DIVISOR, DEFAULT_MAX_USDG_AMOUNT, USD_DECIMALS, importImage } from "lib/legacy";
import { bigNumberify, formatAmount, formatKeyAmount } from "lib/numbers";
import AssetDropdown from "./AssetDropdown";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";

export default function TokenTable(props) {
  const { currentIcons = { network: "" }, visibleTokens = [], infoTokens = {}, getWeightText } = props;

  return (
    <>
      <div className="App-card-title">
        <Trans>SLP Index Composition</Trans> <img src={currentIcons.network} width="16" alt="Network Icon" />
      </div>
      <div className="token-table-wrapper App-card card-container">
        <div className="App-card-divider"></div>
        <table className="token-table">
          <thead>
            <tr>
              <th>Token</th>
              <th>Price</th>
              <th>Pool</th>
              <th>Weight</th>
              <th>Utilization</th>
            </tr>
          </thead>
          <tbody>
            {visibleTokens.map((token) => {
              const tokenInfo = infoTokens[token.address];
              let utilization = bigNumberify(0);
              if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
                utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount);
              }
              let maxUsdgAmount = DEFAULT_MAX_USDG_AMOUNT;
              if (tokenInfo.maxUsdgAmount && tokenInfo.maxUsdgAmount.gt(0)) {
                maxUsdgAmount = tokenInfo.maxUsdgAmount;
              }
              const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_40.svg");

              return (
                <tr key={token.symbol}>
                  <td>
                    <div className="token-symbol-wrapper">
                      <div className="App-card-title-info">
                        <div className="App-card-title-info-icon">
                          <img src={tokenImage} alt={token.symbol} width="40" />
                        </div>
                        <div className="App-card-title-info-text">
                          <div className="App-card-info-title">{token.name}</div>
                          <div className="App-card-info-subtitle">{token.symbol}</div>
                        </div>
                        <div>
                          <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>${formatKeyAmount(tokenInfo, "minPrice", USD_DECIMALS, 2, true)}</td>
                  <td>
                    <Tooltip
                      handle={`$${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 0, true)}`}
                      position="right-bottom"
                      className="nowrap"
                      renderContent={() => {
                        return (
                          <>
                            <StatsTooltipRow
                              label={t`Pool Amount`}
                              value={`${formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 0, true)} ${token.symbol
                                }`}
                              showDollar={false}
                            />
                            <StatsTooltipRow
                              label={t`Target Min Amount`}
                              value={`${formatKeyAmount(tokenInfo, "bufferAmount", token.decimals, 0, true)} ${token.symbol
                                }`}
                              showDollar={false}
                            />
                            <StatsTooltipRow
                              label={t`Max ${tokenInfo.symbol} Capacity`}
                              value={formatAmount(maxUsdgAmount, 18, 0, true)}
                              showDollar={true}
                            />
                          </>
                        );
                      }}
                    />
                  </td>
                  <td>{getWeightText(tokenInfo)}</td>
                  {/* <td>WeightText</td> */}
                  <td>{formatAmount(utilization, 2, 2, false)}%</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
