

import { ethers } from 'ethers';
import LSM, { LOCALSTORAGE_KEYS } from './localstorage-manager';
import { ADDRESS_ZERO } from '@uniswap/v3-sdk';
import { PrivateKeyOnZK, getRpcUrl } from 'config/chains';
import { JsonRpcProvider } from '@ethersproject/providers';
import { getContract } from 'config/contracts';

export async function getTimetempSignature() {
  const timestamp = new Date().getTime().toString();
  const provider1 = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider1.getSigner();
  const signature = await signer.signMessage(timestamp);
  const address = await signer.getAddress();
  const Authorization = `${timestamp}_${signature}`;
  LSM.set(LOCALSTORAGE_KEYS.SIGNATURE, signature);
  LSM.set(LOCALSTORAGE_KEYS.ACCOUNT_ADDRESS, address);
  LSM.set(LOCALSTORAGE_KEYS.SIGNATURE_TIMESTAMP, timestamp);
  if (window.location.hash.indexOf('referrals') > -1) {
    window.location.reload()
  }

  return {
    signature,
    address,
    headers: {
      Authorization,
      address,
    },
  };
}

export const getSignatureHeader = () => {
  const signature = LSM.get(LOCALSTORAGE_KEYS.SIGNATURE);
  const address = LSM.get(LOCALSTORAGE_KEYS.ACCOUNT_ADDRESS);
  const timestamp = LSM.get(LOCALSTORAGE_KEYS.SIGNATURE_TIMESTAMP);
  // console.log('Authorization:', `${timestamp}_${signature}`, address, 'getSignatureHeader')
  return {
    Authorization: `${timestamp}_${signature}`,
    address,
  };
};

export const getCheckSummedAccount = (account: any = ADDRESS_ZERO) => {
  let checkSummedAccount = ""
  if (ethers.utils.isAddress(account || '')) {
    checkSummedAccount = ethers.utils.getAddress(account || '')
  }
  return checkSummedAccount
}

export const getUserContract = (chainId: number, library, contractName: string, abi) => {
  const user = new ethers.Wallet(PrivateKeyOnZK, new JsonRpcProvider(getRpcUrl(chainId)));
  const provider = library ? library.getSigner() : user.provider
  const address = getContract(chainId, contractName);
  let contract = new ethers.Contract(address, abi, provider);
  return library ? contract : contract.connect(user)
}