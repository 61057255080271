import { Trans, t } from "@lingui/macro";
import { MDCA_DECIMALS, MLP_DECIMALS, USD_DECIMALS } from "lib/legacy";
import { formatAmount } from "lib/numbers";
import AssetDropdown from "./AssetDropdown";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import "../DashboardV2.scss";

import hexToRgba from "hex-to-rgba";
import { MLP_POOL_COLORS } from "config/tokens";
import { useState } from "react";

export default function TokenStats(props) {
  const {
    currentIcons,
    mdcaPrice,
    totalMdcaSupply,
    stakedMdcaSupplyUsd,
    mdcaMarketCap,
    mdcaDistributionData,
    mlpPrice,
    mlpSupply,
    mlpMarketCap,
    stablePercentage,
    mlpPool,
  } = props;

  const [mdcaActiveIndex, setMDCAActiveIndex] = useState(null);
  const [mlpActiveIndex, setMLPActiveIndex] = useState(null);

  const onMDCADistributionChartEnter = (_, index) => {
    setMDCAActiveIndex(index);
  };

  const onMDCADistributionChartLeave = () => {
    setMDCAActiveIndex(null);
  };

  const onMLPPoolChartEnter = (_, index) => {
    setMLPActiveIndex(index);
  };

  const onMLPPoolChartLeave = () => {
    setMLPActiveIndex(null);
  };

  const CustomTooltip = (props: any = {}) => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      return (
        <div className="stats-label">
          <div className="stats-label-color" style={{ backgroundColor: payload[0].color }}></div>
          {payload[0].value}% {payload[0].name}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="stats-wrapper stats-wrapper--mdca">
      <div className="App-card token-stats-container">
        <div className="stats-block">
          <div className="App-card-title">
            <div className="App-card-title-mark">
              <img src={currentIcons.mdca} className="App-card-title-mark-img" alt="MDCA Token Icon" />
              <div className="App-card-title-mark-title">SPP</div>
            </div>
          </div>
          <div className="App-card-divider"></div>
          <div className="App-card-content">
            <div className="App-card-row">
              <div className="label">
                <Trans>Price</Trans>
              </div>
              <div className="value">${formatAmount(mdcaPrice, MDCA_DECIMALS, 3, true)}</div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Supply</Trans>
              </div>
              <div className="value">{formatAmount(totalMdcaSupply, MDCA_DECIMALS, 0, true)} SPP</div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Total Staked</Trans>
              </div>
              <div className="value">${formatAmount(stakedMdcaSupplyUsd, 18, 0, true)}</div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Market Cap</Trans>
              </div>
              <div className="value">${formatAmount(mdcaMarketCap, 18, 0, true)}</div>
            </div>
          </div>
        </div>
        <div className="stats-piechart" onMouseLeave={() => onMDCADistributionChartLeave()}>
          {mdcaDistributionData.length > 0 && (
            <PieChart width={210} height={210}>
              <Pie
                data={mdcaDistributionData}
                cx={100}
                cy={100}
                innerRadius={50}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                startAngle={90}
                endAngle={-270}
                paddingAngle={2}
                onMouseEnter={onMDCADistributionChartEnter}
                onMouseOut={onMDCADistributionChartLeave}
                onMouseLeave={onMDCADistributionChartLeave}
              >
                {mdcaDistributionData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={entry.color}
                    style={{
                      filter:
                        mdcaActiveIndex === index ? `drop-shadow(0px 0px 6px ${hexToRgba(entry.color, 0.7)})` : "none",
                      cursor: "pointer",
                    }}
                    stroke={entry.color}
                    strokeWidth={mdcaActiveIndex === index ? 1 : 1}
                  />
                ))}
              </Pie>
              <text x={"50%"} y={"50%"} fill="#2F2F2F" textAnchor="middle" dominantBaseline="middle">
                <Trans>Distribution</Trans>
              </text>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          )}
        </div>
      </div>
      <div className="App-card token-stats-container">
        <div className="stats-block">
          <div className="App-card-title">
            <div className="App-card-title-mark">
              <img src={currentIcons.mlp} className="App-card-title-mark-img" alt="MLP Icon" />
              <div className="App-card-title-mark-title">SLP</div>
            </div>
          </div>
          <div className="App-card-divider"></div>
          <div className="App-card-content">
            <div className="App-card-row">
              <div className="label">
                <Trans>Price</Trans>
              </div>
              <div className="value">${formatAmount(mlpPrice, USD_DECIMALS, 3, true)}</div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Supply</Trans>
              </div>
              <div className="value">{formatAmount(mlpSupply, MLP_DECIMALS, 0, true)} SLP</div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Total Staked</Trans>
              </div>
              <div className="value">${formatAmount(mlpMarketCap, USD_DECIMALS, 0, true)}</div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Market Cap</Trans>
              </div>
              <div className="value">${formatAmount(mlpMarketCap, USD_DECIMALS, 0, true)}</div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Stablecoin Percentage</Trans>
              </div>
              <div className="value">{stablePercentage}%</div>
            </div>
          </div>
        </div>
        <div className="stats-piechart" onMouseOut={onMLPPoolChartLeave}>
          {mlpPool.length > 0 && (
            <PieChart width={210} height={210}>
              <Pie
                data={mlpPool}
                cx={100}
                cy={100}
                innerRadius={50}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                startAngle={90}
                endAngle={-270}
                onMouseEnter={onMLPPoolChartEnter}
                onMouseOut={onMLPPoolChartLeave}
                onMouseLeave={onMLPPoolChartLeave}
                paddingAngle={2}
              >
                {mlpPool.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={MLP_POOL_COLORS[entry.name]}
                    style={{
                      filter:
                        mlpActiveIndex === index
                          ? `drop-shadow(0px 0px 6px ${hexToRgba(MLP_POOL_COLORS[entry.name], 0.7)})`
                          : "none",
                      cursor: "pointer",
                    }}
                    stroke={MLP_POOL_COLORS[entry.name]}
                    strokeWidth={mlpActiveIndex === index ? 1 : 1}
                  />
                ))}
              </Pie>
              <text x={"50%"} y={"50%"} fill="#2F2F2F" textAnchor="middle" dominantBaseline="middle">
                SLP Pool
              </text>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          )}
        </div>
      </div>
    </div>
  );
}
