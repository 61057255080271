import React from "react";

import cx from "classnames";

import "./Checkbox.css";
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im";
import ImgChecked from 'img/mdca/checked.svg'
import ImgCheckedDisabled from 'img/mdca/checked-disabled.svg'
import ImgUnChecked from 'img/mdca/unchecked.svg'
import ImgUnCheckedDisabled from 'img/mdca/unchecked-disabled.svg'

export default function Checkbox(props) {
  const { isChecked, setIsChecked, disabled, className } = props;

  return (
    <div
      className={cx("Checkbox", { disabled, selected: isChecked }, className)}
      onClick={() => setIsChecked(!isChecked)}
    >
      <span className="Checkbox-icon-wrapper">
        {isChecked && !disabled && <img src={ImgChecked} alt="checked" className="App-icon Checkbox-icon active" />}
        {isChecked && disabled && <img src={ImgCheckedDisabled} alt="checked" className="App-icon Checkbox-icon active" />}
        {!isChecked && !disabled && <img src={ImgUnChecked} alt='unchecked' className="App-icon Checkbox-icon inactive" />}
        {!isChecked && disabled && <img src={ImgUnCheckedDisabled} alt='unchecked' className="App-icon Checkbox-icon inactive" />}
      </span>
      <span className="Checkbox-label">{props.children}</span>
    </div>
  );
}
