import React, { useState } from "react";
import Modal from "components/Modal/Modal";
import "../StakeV2.css";
import { BuyMDCAInUniswapUrl, BuyMDCAInDoDoUrl } from "config/links";
import ImgUniswap from "img/earn/token/uniswap.png";
import ImgDodo from "img/earn/token/dodo.png";

export default function BuyMDCAModal(props) {
  const { isVisible, setIsVisible } = props;

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={`Buy SPP`}>
        <div className="buy-mdca-contianer">
          <div className="btn-item" onClick={() => window.open(BuyMDCAInUniswapUrl)}>
            <img className="img" src={ImgUniswap} alt="unisawap" />
            <span>Uniswap</span>
          </div>
          <div className="btn-item btn-item-top24" onClick={() => window.open(BuyMDCAInDoDoUrl)}>
            <img className="img" src={ImgDodo} alt="dodo" />
            <span>DoDo</span>
          </div>
        </div>
      </Modal>
    </div>
  );
}
