import React, { useMemo, useState } from "react";
import { t, Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";

import Footer from "components/Footer/Footer";
import MDCAStakingPool from "abis/MDCAStakingPool.json";
import helpIcon from "img/help.svg";
import { getServerBaseUrl } from "config/backend";
import { requestGet } from "lib/api/index";
import { useRequest } from "ahooks";
import BuySellMPLModal from "./compoonents/BuySellMPLModal";
import MDCA from "abis/MDCA.json";
import MLP from "abis/MLP.json";
import MlpManager from "abis/MlpManager.json";
import ETHRewardDistributor from "abis/ETHRewardDistributor.json";
import ETHRewardDistributorForMLP from "abis/ETHRewardDistributorForMLP.json";
import TokenRewardDistributor from "abis/TokenRewardDistributor.json";
import TokenRewardDistributorForMLP from "abis/TokenRewardDistributorForMLP.json";

import { BigNumber, ethers } from "ethers";
import { getContract } from "config/contracts";

import "./StakeV2.css";
import { callContract } from "lib/contracts";
import { bigNumberify, formatAmount, numberWithCommas } from "lib/numbers";
import { useChainId } from "lib/chains";
import Button from "components/Button/Button";
import StakeModal from "./compoonents/StakeModal";
import UnstakeModal from "./compoonents/UnstakeModal";
import ClaimModal from "./compoonents/ClaimModal";
import CompoundModal from "./compoonents/CompoundModal";
import usePrices from "hooks/usePrices";
import Tooltip from "components/Tooltip/Tooltip";
import BuyMDCAModal from "./compoonents/BuyMDCAModal";
import { PollingInterval } from "config/utils";
import { SPP_DECIMALS, SLP_DECIMALS } from 'lib/legacy';
import { getCheckSummedAccount, getUserContract } from 'utils';

export default function StakeV2({ setPendingTxns, connectWallet, savedSlippageAmount }) {
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const [isStakeModalVisible, setIsStakeModalVisible] = useState(false);
  const [stakeValue, setStakeValue] = useState("");
  const [isUnstakeModalVisible, setIsUnstakeModalVisible] = useState(false);
  const [unstakeModalMaxAmount, setUnstakeModalMaxAmount] = useState(undefined);
  const [unstakeValue, setUnstakeValue] = useState("");
  const [isCompoundModalVisible, setIsCompoundModalVisible] = useState(false);
  const [isClaimModalVisible, setIsClaimModalVisible] = useState(false);
  const [isBuySellModalVisible, setIsBuySellModalVisible] = useState(false);
  const [isBuyMDCAModalVisible, setIsBuyMDCAModalVisible] = useState(false);
  const rewardRouterAddress = getContract(chainId, "RewardRouter");
  const [isBuying, setIsBuying] = useState(true);

  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const {
    prices: { [nativeTokenAddress]: EHTPrice },
  } = usePrices(chainId);

  const CurrentDecimals = 18;

  let checkSummedAccount = getCheckSummedAccount(account)
  // checkSummedAccount = MOCK ? mocakAccount : checkSummedAccount
  const defaultEarnData = {
    mdcaStakeAmount: "0", //mdca总质押数量 带精度展开 比如 1 mdca 这里返回 1000 000 000 000 000 000 000
    mlpAmount: "0", //mlp数量
    boostPointStakeAmount: "0", // boostPoint 质押数量
    boostPointPendingAmount: "0", // boostPoint 待领取数量
    boostPointAPR: 1, // boostPoint 质押apr
    mdcaStakeReward: "0", // mdca质押 给与的mdca奖励数量
    mdcaStakeEthReward: "0", // mdca质押 给与的eth奖励数量
    mlpReward: "0", // mlp持有者 mdca奖励数量
    mlpEthReward: "0", // mlp持有至 eth奖励数量
    totalReward: "0", // 奖励的总mdca数量  待领取的数量 需要用这个值-从合约里取到的已领取数量
    totalEthReward: "0", // 奖励的总eth数量
    mdcaStakeAPR: "0", // mdca质押apr 小数 非%
    mlpARP: "0", // mlp质押apr 同上
    mdcaPrice: "1",
  };
  const EarnBaseUrl = `${getServerBaseUrl(chainId)}`;

  const { data: { data: earnData } = { data: defaultEarnData } } = useRequest(
    () => {
      return requestGet(`${EarnBaseUrl}/earn-info`, { account: checkSummedAccount });
    },
    {
      refreshDeps: [checkSummedAccount],
      manual: false,
      pollingInterval: PollingInterval,
      // onSuccess: (data) => {
      //   console.log("chf-success-earnData", data);
      // },
      // onError: (data) => {
      //   console.log("chf-error-earnData", data);
      // },
    }
  );

  const MDCAPrice = useMemo(() => {
    if (!earnData) return "1";
    return earnData.mdcaPrice.toString();
  }, [earnData]);

  const { data: { data: rewardProofData } = { data: {} } } = useRequest(
    () => {
      if (!checkSummedAccount) {
        return { data: {} };
      }
      return requestGet(`${EarnBaseUrl}/reward-proof`, { account: checkSummedAccount });
    },
    {
      // manual: true,
      refreshDeps: [checkSummedAccount],
      pollingInterval: PollingInterval,
      // onSuccess: ({ data }) => {
      //   console.log("chf-rewardProofData", data);
      // },
    }
  );

  const { data: userStakedVolumn = "0" } = useRequest(
    () => {
      if (!checkSummedAccount || !library) {
        return "0";
      }
      const contract = getUserContract(chainId, library, "MDCAStakingPool", MDCAStakingPool.abi)
      return callContract(chainId, contract, "userStaked", [checkSummedAccount], {
        hideSuccessMsg: true,
        hideErrorMsg: true,
      });
    },
    {
      // manual: true,
      refreshDeps: [checkSummedAccount, library],
      pollingInterval: PollingInterval,
      // onSuccess: (data) => {
      //   console.log("chf-userStakedVolumn success", data.toString());
      // },
    }
  );

  const { data: totalStakedVolumnForMDA = "0" } = useRequest(
    () => {
      const contract = getUserContract(chainId, library, "MDCAStakingPool", MDCAStakingPool.abi)
      return callContract(chainId, contract, "totalStaked", [], {
        hideSuccessMsg: true,
        hideErrorMsg: true,
      });
    },
    {
      // manual: true,
      refreshDeps: [checkSummedAccount, library],
      pollingInterval: PollingInterval,
      // onSuccess: (data) => {
      //   console.log("chf-totalStakedVolumnForMDA success", data.toString());
      // },
    }
  );

  const { data: totalSupplyVolumnForMDCA = "0" } = useRequest(
    () => {
      const contract = getUserContract(chainId, library, "MDCA", MDCA.abi)
      return callContract(chainId, contract, "totalSupply", [], {
        hideSuccessMsg: true,
        hideErrorMsg: true,
      });
    },
    {
      // manual: true,
      refreshDeps: [checkSummedAccount, library],
      pollingInterval: PollingInterval,
      // onSuccess: (data) => {
      //   console.log("chf-totalSupplyVolumnForMDCA success", data.toString());
      // },
    }
  );

  const { data: totalSupplyVolumnForMLP = "0" } = useRequest(
    () => {
      const contract = getUserContract(chainId, library, "MLP", MLP.abi)
      return callContract(chainId, contract, "totalSupply", [], {
        hideSuccessMsg: true,
        hideErrorMsg: true,
      });
    },
    {
      // manual: true,
      refreshDeps: [checkSummedAccount, library],
      pollingInterval: PollingInterval,
      // onSuccess: (data) => {
      //   console.log("chf-totalSupplyVolumnForMLP success", data.toString());
      // },
    }
  );

  const { data: balanceForMDCA = "0" } = useRequest(
    () => {
      if (!checkSummedAccount || !library) {
        return "0";
      }
      const address = getContract(chainId, "MDCA");
      const contract = new ethers.Contract(address, MDCA.abi, library.getSigner());
      return callContract(chainId, contract, "balanceOf", [checkSummedAccount], {
        hideSuccessMsg: true,
        hideErrorMsg: true,
      });
    },
    {
      // manual: true,
      refreshDeps: [checkSummedAccount, library],
      pollingInterval: PollingInterval,
      // onSuccess: (data) => {
      //   console.log("chf-success-balanceForMDCA ", data.toString());
      // },
      // onError: (error) => {
      //   console.log("chf-error-balanceForMDCA", error);
      // },
    }
  );

  const { data: mlpPrice = "1", run: getPriceFun } = useRequest(
    () => {
      const contract = getUserContract(chainId, library, "MlpManager", MlpManager.abi)
      return callContract(chainId, contract, "getPrice", [true], {
        hideSuccessMsg: true,
        hideErrorMsg: true,
      });
    },
    {
      // manual: true,
      refreshDeps: [checkSummedAccount, library],
      pollingInterval: PollingInterval,
      // onSuccess: (data) => {
      //   console.log("chf-success-mlpPrice", data.toString());
      // },
      // onError: (error) => {
      //   console.log("chf-error-mlpPrice", error);
      // },
    }
  );

  const { data: userClaimedAmountEthForMDCA = "0" } = useRequest(
    () => {
      if (!checkSummedAccount || !library) {
        return 0;
      }
      const address = getContract(chainId, "ETHRewardDistributor");
      const contract = new ethers.Contract(address, ETHRewardDistributor.abi, library.getSigner());
      return callContract(chainId, contract, "userClaimedAmount", [checkSummedAccount], {
        hideSuccessMsg: true,
        hideErrorMsg: true,
      });
    },
    {
      // manual: true,
      refreshDeps: [checkSummedAccount, library],
      pollingInterval: PollingInterval,
      // onSuccess: (data) => {
      //   console.log("chf-success-userClaimedAmountEthForMDCA", data.toString());
      // },
      // onError: (error) => {
      //   console.log("chf-error-userClaimedAmountEthForMDCA", error);
      // },
    }
  );

  const { data: userClaimedAmountEthForMLP = "0" } = useRequest(
    () => {
      if (!checkSummedAccount || !library) {
        return 0;
      }
      const address = getContract(chainId, "ETHRewardDistributorForMLP");
      const contract = new ethers.Contract(address, ETHRewardDistributorForMLP.abi, library.getSigner());
      return callContract(chainId, contract, "userClaimedAmount", [checkSummedAccount], {
        hideSuccessMsg: true,
        hideErrorMsg: true,
      });
    },
    {
      // manual: true,
      refreshDeps: [checkSummedAccount, library],
      pollingInterval: PollingInterval,
      // onSuccess: (data) => {
      //   console.log("chf-success-userClaimedAmountEthForMLP", data.toString());
      // },
      // onError: (error) => {
      //   console.log("chf-error-userClaimedAmountEthForMLP", error);
      // },
    }
  );

  const { data: userClaimedAmountMdcaForMDCA = "0" } = useRequest(
    () => {
      if (!checkSummedAccount || !library) {
        return 0;
      }
      const address = getContract(chainId, "TokenRewardDistributor");
      const contract = new ethers.Contract(address, TokenRewardDistributor.abi, library.getSigner());
      return callContract(chainId, contract, "userClaimedAmount", [checkSummedAccount], {
        hideSuccessMsg: true,
        hideErrorMsg: true,
      });
    },
    {
      // manual: true,
      refreshDeps: [checkSummedAccount, library],
      pollingInterval: PollingInterval,
      // onSuccess: (data) => {
      //   console.log("chf-success-userClaimedAmountMdcaForMDCA", data.toString());
      // },
      // onError: (error) => {
      //   console.log("chf-error-userClaimedAmountMdcaForMDCA", error);
      // },
    }
  );

  const { data: userClaimedAmountMdcaForMLP = "0" } = useRequest(
    () => {
      if (!checkSummedAccount || !library) {
        return 0;
      }
      const address = getContract(chainId, "TokenRewardDistributorForMLP");
      const contract = new ethers.Contract(address, TokenRewardDistributorForMLP.abi, library.getSigner());
      return callContract(chainId, contract, "userClaimedAmount", [checkSummedAccount], {
        hideSuccessMsg: true,
        hideErrorMsg: true,
      });
    },
    {
      // manual: true,
      refreshDeps: [checkSummedAccount, library],
      pollingInterval: PollingInterval,
      // onSuccess: (data) => {
      //   console.log("chf-success-userClaimedAmountMdcaForMLP", data.toString());
      // },
      // onError: (error) => {
      //   console.log("chf-error-userClaimedAmountMdcaForMLP", error);
      // },
    }
  );

  const showStakeModal = () => {
    setIsStakeModalVisible(true);
    setStakeValue("");
  };

  const showUnstakeModal = () => {
    setIsUnstakeModalVisible(true);
    setUnstakeModalMaxAmount(userStakedVolumn);
    setUnstakeValue("");
  };

  const showClaimModal = () => {
    setIsClaimModalVisible(true);
  };

  const showCompoundModal = () => {
    setIsCompoundModalVisible(true);
  };

  const getError = () => {
    // if (!amount || amount.eq(0)) {
    //   return t`Enter an amount`;
    // }
    // if (maxAmount && amount.gt(maxAmount)) {
    //   return t`Max amount exceeded`;
    // }
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    // if (isApproving) {
    //   return t`Approving ${stakingTokenSymbol}...`;
    // }
    // if (needApproval) {
    //   return t`Approve ${stakingTokenSymbol}`;
    // }
    // if (isStaking) {
    //   return t`Staking...`;
    // }
    return t`Stake`;
  };

  const onClickPrimary = () => {
    if (!active) {
      connectWallet();
      return;
    }
  };

  const myStakeForMDCA = formatAmount(bigNumberify(earnData?.mdcaStakeAmount), SPP_DECIMALS, 2);
  const myStakeForMLP = formatAmount(bigNumberify(earnData?.mlpAmount), SLP_DECIMALS, 2);

  const pendingMDCAReward = useMemo(() => {
    const userClaimedAmount = bigNumberify(userClaimedAmountMdcaForMDCA).add(userClaimedAmountMdcaForMLP);
    const pendingReward = bigNumberify(earnData?.totalReward).sub(userClaimedAmount);
    const result = formatAmount(pendingReward, SPP_DECIMALS, 4);
    console.log(earnData?.totalReward?.toString(), userClaimedAmount?.toString(), pendingReward?.toString(), result, 'pendingMDCAReward')
    return result;
  }, [earnData, userClaimedAmountMdcaForMDCA, userClaimedAmountMdcaForMLP]);

  const pendingETHReward = useMemo(() => {
    const userClaimedAmount = bigNumberify(userClaimedAmountEthForMDCA).add(userClaimedAmountEthForMLP);
    const pendingReward = bigNumberify(earnData?.totalEthReward).sub(userClaimedAmount);
    const result = formatAmount(pendingReward, SLP_DECIMALS, 4);
    return result;
  }, [earnData, userClaimedAmountEthForMDCA, userClaimedAmountEthForMLP]);

  const shouldClaimMDCARewardDisabled = useMemo(() => {
    return Number(pendingMDCAReward) <= 0;
  }, [pendingMDCAReward]);

  const shouldClaimEthRewardDisabled = useMemo(() => {
    return Number(pendingETHReward) <= 0;
  }, [pendingETHReward]);

  const [
    sholdEthForMDCAClaimDisabled,
    shouldEthForMLPClaimDisabled,
    sholdMdcaForMDCAClaimDisabled,
    sholdMdcaForMLPClaimDisabled,
  ] = useMemo(() => {
    if (!rewardProofData || !Object.keys(rewardProofData).length) {
      return [true, true, true, true];
    }
    const { mdcaStakeReward, mlpReward, mdcaStakeETHReward, mlpETHReward } = rewardProofData;
    const a = bigNumberify(mdcaStakeETHReward).sub(userClaimedAmountEthForMDCA).lte(0);
    const b = bigNumberify(mlpETHReward).sub(userClaimedAmountEthForMLP).lte(0);
    const c = bigNumberify(mdcaStakeReward).sub(userClaimedAmountMdcaForMDCA).lte(0);
    const d = bigNumberify(mlpReward).sub(userClaimedAmountMdcaForMLP).lte(0);
    console.log("chf-abcd-a:", a, mdcaStakeETHReward, userClaimedAmountEthForMDCA);
    console.log("chf-abcd-b:", b, mlpETHReward, userClaimedAmountEthForMLP);
    console.log("chf-abcd-c:", c, mdcaStakeReward, userClaimedAmountMdcaForMDCA);
    console.log("chf-abcd-d:", d, mlpReward, userClaimedAmountMdcaForMLP);
    return [a, b, c, d];
  }, [
    rewardProofData,
    userClaimedAmountMdcaForMDCA,
    userClaimedAmountMdcaForMLP,
    userClaimedAmountEthForMDCA,
    userClaimedAmountEthForMLP,
  ]);

  const CompoundOrClaimDisable = useMemo(() => {
    return shouldClaimMDCARewardDisabled && shouldClaimEthRewardDisabled;
  }, [shouldClaimMDCARewardDisabled, shouldClaimEthRewardDisabled]);

  const totalStakedForMDCA = formatAmount(bigNumberify(totalStakedVolumnForMDA), CurrentDecimals, 2);
  const totalSupplyForMDCA = formatAmount(bigNumberify(totalSupplyVolumnForMDCA), CurrentDecimals, 2);
  const totalSupplyForMLP = formatAmount(bigNumberify(totalSupplyVolumnForMLP), CurrentDecimals, 2);
  const MLPPrice = formatAmount(bigNumberify(mlpPrice), 30, 2);
  const myBoostPointPercentage =
    !!earnData && (Number(earnData.boostPointStakeAmount) > 0 && Number(myStakeForMDCA)) > 0
      ? `${(Number(earnData.boostPointStakeAmount) / Number(myStakeForMDCA)).toFixed(4) * 100}%`
      : "0.00%";
  return (
    <div className="default-container page-layout">
      <StakeModal
        isVisible={isStakeModalVisible}
        setIsVisible={setIsStakeModalVisible}
        chainId={chainId}
        maxAmount={balanceForMDCA}
        value={stakeValue}
        setValue={setStakeValue}
        active={active}
        account={account}
        library={library}
        stakingTokenSymbol={"SPP"}
        stakingTokenAddress={getContract(chainId, "MDCA")}
        farmAddress={getContract(chainId, "MDCAStakingPool")}
        stakeMethodName={"stake"}
        setPendingTxns={setPendingTxns}
      />
      <UnstakeModal
        setPendingTxns={setPendingTxns}
        isVisible={isUnstakeModalVisible}
        setIsVisible={setIsUnstakeModalVisible}
        chainId={chainId}
        maxAmount={unstakeModalMaxAmount}
        value={unstakeValue}
        setValue={setUnstakeValue}
        library={library}
        unstakingTokenSymbol={"SPP"}
        rewardRouterAddress={rewardRouterAddress}
        unstakeMethodName={"unstake"}
      />
      <CompoundModal
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isCompoundModalVisible}
        setIsVisible={setIsCompoundModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        library={library}
        chainId={chainId}
        rewardProofData={rewardProofData}
        shouldClaimMDCARewardDisabled={shouldClaimMDCARewardDisabled}
        shouldClaimEthRewardDisabled={shouldClaimEthRewardDisabled}
        sholdEthForMDCAClaimDisabled={sholdEthForMDCAClaimDisabled}
        shouldEthForMLPClaimDisabled={shouldEthForMLPClaimDisabled}
        sholdMdcaForMDCAClaimDisabled={sholdMdcaForMDCAClaimDisabled}
        sholdMdcaForMLPClaimDisabled={sholdMdcaForMLPClaimDisabled}
      />
      <ClaimModal
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isClaimModalVisible}
        setIsVisible={setIsClaimModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        library={library}
        chainId={chainId}
        rewardProofData={rewardProofData}
        shouldClaimMDCARewardDisabled={shouldClaimMDCARewardDisabled}
        shouldClaimEthRewardDisabled={shouldClaimEthRewardDisabled}
        sholdEthForMDCAClaimDisabled={sholdEthForMDCAClaimDisabled}
        shouldEthForMLPClaimDisabled={shouldEthForMLPClaimDisabled}
        sholdMdcaForMDCAClaimDisabled={sholdMdcaForMDCAClaimDisabled}
        sholdMdcaForMLPClaimDisabled={sholdMdcaForMLPClaimDisabled}
      />
      <BuySellMPLModal
        savedSlippageAmount={savedSlippageAmount}
        isVisible={isBuySellModalVisible}
        setIsVisible={setIsBuySellModalVisible}
        mlpPrice={mlpPrice}
        checkSummedAccount={checkSummedAccount}
        isBuying={isBuying}
        setIsBuying={setIsBuying}
      />
      <BuyMDCAModal isVisible={isBuyMDCAModalVisible} setIsVisible={setIsBuyMDCAModalVisible} />
      <div className="StakeV2-content">
        <div className="StakeV2-cards">
          <div className="StakeV2-cards-left">
            <h2>SPP</h2>
            <span>
              <Trans>My Staked</Trans>
            </span>
            <div className="StakeV2-cards-title">
              <div className="StakeV2-cards-title-box">
                {numberWithCommas(myStakeForMDCA)} SPP
                <span>(${numberWithCommas((Number(myStakeForMDCA) * MDCAPrice).toFixed(2))})</span>
              </div>
              <div className="StakeV2-cards-title-box">
                {numberWithCommas(Number(earnData?.boostPointStakeAmount).toFixed(2))}
                {' Boost Point'}
              </div>
            </div>
            <div className="StakeV2-cards-buttons">
              {active && (
                <>
                  <Button
                    variant="primary-action"
                    className="btn"
                    // onClick={onClickPrimary}
                    onClick={() => showStakeModal()}
                  // disabled={!isPrimaryEnabled()}
                  >
                    {getPrimaryText()}
                  </Button>
                  <Button
                    variant="primary-action"
                    className="btn btns"
                    // onClick={onClickPrimary}
                    onClick={() => showUnstakeModal()}
                  // disabled={!isPrimaryEnabled()}
                  >
                    <Trans>Unstake</Trans>
                  </Button>
                </>
              )}
              <Button
                variant="primary-action"
                className="btn"
                onClick={() => setIsBuyMDCAModalVisible(true)}
              // disabled={!isPrimaryEnabled()}
              >
                <Trans>Buy SPP</Trans>
              </Button>
            </div>
            <div className="StakeV2-cards-item">
              <div className="StakeV2-cards-item-row">
                <span>
                  <Trans>Price</Trans>:
                </span>
                <p>${numberWithCommas(Number(MDCAPrice).toFixed(2))}</p>
              </div>
              <div className="StakeV2-cards-item-row">
                <span>
                  <Trans>Total Staked</Trans>:
                </span>
                <p>
                  {numberWithCommas(totalStakedForMDCA)}SPP ($
                  {numberWithCommas((Number(totalStakedForMDCA) * MDCAPrice).toFixed(2))})
                </p>
              </div>
              <div className="StakeV2-cards-item-row">
                <span>
                  <Trans>Total Supply</Trans>:
                </span>
                <p>
                  {numberWithCommas(totalSupplyForMDCA)}SPP ($
                  {numberWithCommas((Number(totalSupplyForMDCA) * MDCAPrice).toFixed(2))})
                </p>
              </div>
            </div>
          </div>
          <div className="StakeV2-cards-right">
            <div className="StakeV2-cards-data-item">
              <Tooltip
                handle={
                  <span>
                    {Number(myStakeForMDCA) > 0 ? "My" : ""} <Trans>SPP APR</Trans> <img src={helpIcon} alt="spp apr" />
                  </span>
                }
                position="right-top"
                renderContent={() => {
                  return (
                    <div>
                      <Trans>APRs are updated daily at UTC 8:00 and will depend on the fees collected.</Trans>
                    </div>
                  );
                }}
              />
              <p>
                {!!earnData && earnData.mdcaStakeAPR && earnData.mdcaStakeAPR !== "NaN"
                  ? `${(Number(earnData.mdcaStakeAPR) * 100).toFixed(2)}%`
                  : "-"}
              </p>
            </div>
            <div className="StakeV2-cards-data-item">
              <Tooltip
                handle={
                  <span>
                    <Trans>My Boost Percentage</Trans> <img src={helpIcon} alt="bp apr" />
                  </span>
                }
                position="right-top"
                renderContent={() => {
                  return (
                    <div>
                      <div>
                        <Trans>My Boost Percentage=Staked Boost Points/Staked SPP</Trans>
                      </div>
                      <br />
                      <div>
                        <Trans>Use the "Compound" button to stake your Boost Points and Earn More Rewards.</Trans>
                      </div>
                    </div>
                  );
                }}
              />
              <p className="StakeV2-cards-data-item-small">{myBoostPointPercentage}</p>
            </div>
            <div className="StakeV2-cards-data-item">
              <Tooltip
                handle={
                  <span>
                    <Trans>Boost Point APR</Trans> <img src={helpIcon} alt="bp apr" />
                  </span>
                }
                position="right-top"
                renderContent={() => {
                  return (
                    <div>
                      <Trans>Boost your rewards with Boost Points.</Trans>
                    </div>
                  );
                }}
              />
              <p className="StakeV2-cards-data-item-small">{Number(earnData?.boostPointAPR).toFixed(4) * 100}%</p>
            </div>
          </div>
        </div>
        <div className="StakeV2-cards">
          <div className="StakeV2-cards-left">
            <h2>SLP</h2>
            <span>
              <Trans>My Staked</Trans>
            </span>
            <div className="StakeV2-cards-title">
              <div className="StakeV2-cards-title-box">
                {numberWithCommas(myStakeForMLP)} SLP
                <span>(${numberWithCommas((Number(myStakeForMLP) * Number(MLPPrice)).toFixed(2))})</span>
              </div>
            </div>
            <div className="StakeV2-cards-buttons">
              {active && (
                <>
                  <Button
                    variant="primary-action"
                    className="btn"
                    onClick={() => {
                      setIsBuying(true);
                      setIsBuySellModalVisible(true);
                      getPriceFun();
                    }}
                  >
                    <Trans>Buy SLP</Trans>
                  </Button>
                  <Button
                    variant="primary-action"
                    className="btn btns"
                    onClick={() => {
                      setIsBuying(false);
                      setIsBuySellModalVisible(true);
                      getPriceFun();
                    }}
                  >
                    <Trans>Sell SLP</Trans>
                  </Button>
                </>
              )}
              {!active && (
                <Button variant="primary-action" onClick={onClickPrimary}>
                  <Trans>Connect Wallet</Trans>
                </Button>
              )}
            </div>
            <div className="StakeV2-cards-item">
              <div className="StakeV2-cards-item-row">
                <span>
                  <Trans>Price</Trans>:
                </span>
                <p>${MLPPrice}</p>
              </div>
              <div className="StakeV2-cards-item-row">
                <span>
                  <Trans>Total Supply</Trans>:
                </span>
                <p>
                  {numberWithCommas(totalSupplyForMLP)}SLP ($
                  {numberWithCommas((Number(totalSupplyForMLP) * MLPPrice).toFixed(2))})
                </p>
              </div>
            </div>
          </div>
          <div className="StakeV2-cards-right">
            <div className="StakeV2-cards-data-item">
              <Tooltip
                handle={
                  <span>
                    <Trans>SLP APR</Trans> <img src={helpIcon} alt="help" />
                  </span>
                }
                position="right-top"
                renderContent={() => {
                  return (
                    <div>
                      <Trans>APRs are updated daily at UTC 8:00 and will depend on the fees collected.</Trans>
                    </div>
                  );
                }}
              />
              <p>{(Number(earnData?.mlpARP) * 100).toFixed(2)}%</p>
            </div>
          </div>
        </div>
        <div className="StakeV2-cards">
          <div className="StakeV2-cards-left">
            <div className="Rewards-title">
              <Trans>Pending Rewards</Trans>
            </div>
            <div className="Rewards-asset">
              <div className="Rewards-asset-item">
                <span>SPP:</span>
                <p>
                  {numberWithCommas(pendingMDCAReward)} ($
                  {numberWithCommas((Number(pendingMDCAReward) * Number(MDCAPrice)).toFixed(2))})
                </p>
              </div>
              <div className="Rewards-asset-item">
                <span>ETH:</span>
                <p>
                  {numberWithCommas(pendingETHReward)} ($
                  {numberWithCommas(
                    (Number(pendingETHReward) * Number(formatAmount(EHTPrice, 30, 2))).toFixed(2)
                  )}
                  )
                </p>
              </div>
              <div className="Rewards-asset-item">
                <span>Boost Point:</span>
                <p>{numberWithCommas(Number(earnData?.boostPointPendingAmount).toFixed(4))}</p>
              </div>
            </div>
            <div className="Rewards-btn">
              {active && (
                <>
                  <Button
                    variant="primary-action"
                    className="btn"
                    // onClick={() => onBatcCompound()}
                    onClick={showCompoundModal}
                    disabled={CompoundOrClaimDisable}
                  >
                    <Trans>Compound</Trans>
                  </Button>
                  <Button
                    variant="primary-action"
                    className="btn"
                    // onClick={() => onBatchClaim()}
                    onClick={showClaimModal}
                    disabled={CompoundOrClaimDisable}
                  >
                    <Trans>Claim</Trans>
                  </Button>
                </>
              )}
              {!active && (
                <Button variant="primary-action" className="btn" onClick={onClickPrimary}>
                  <Trans>Connect Wallet</Trans>
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
