import { useRequest } from 'ahooks';
import { getContract } from 'config/contracts';
import { ethers } from 'ethers';
import MlpManager from 'abis/MlpManager.json'
import MLP from 'abis/MLP.json'
import { callContract } from 'lib/contracts';
import { PollingInterval } from 'config/utils';
import { expandDecimals } from 'lib/numbers';
import { MLP_DECIMALS } from 'lib/legacy';
import { ADDRESS_ZERO } from '@uniswap/v3-sdk';
import { getUserContract } from 'utils';

export function useMlpPrice(chainId: number, library, account = ADDRESS_ZERO) {
  const defaultValue = expandDecimals(1, MLP_DECIMALS)
  const { data: mlpPrice = defaultValue, run: getMlpPrice } = useRequest(async () => {
    const contract = getUserContract(chainId, library, "MlpManager", MlpManager.abi)
    return callContract(chainId, contract, "getPrice", [true], {
      hideSuccessMsg: true,
      hideErrorMsg: true,
    })
  }, {
    refreshDeps: [library],
    pollingInterval: PollingInterval,
    // onError: (data) => {
    //   console.log('chf-error-useMlpPrice ', data)
    // },
    // onSuccess: (data) => {
    //   console.log('chf-onSuccess-useMlpPrice ', data.toString())
    // },
  });
  return { mlpPrice, getMlpPrice }
}
export function useMlpTotalSupply(chainId: number, library, account) {
  const defaultValue = expandDecimals(0, MLP_DECIMALS)
  const { data: mlpTotalSupply = defaultValue } = useRequest(() => {
    const contract = getUserContract(chainId, library, "MLP", MLP.abi)
    return callContract(chainId, contract, "totalSupply", [], {
      hideSuccessMsg: true,
      hideErrorMsg: true,
    })
  }, {
    refreshDeps: [library],
    pollingInterval: PollingInterval,
    // onSuccess: (data) => {
    //   console.log('chf-success-totalSupplyVolumnForMLP ', data.toString())
    // },
    // onError: (data) => {
    //   console.log('chf-error-totalSupplyVolumnForMLP ', data)
    // },

  });
  return { mlpTotalSupply }
}