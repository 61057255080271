import { isMobileDevice } from 'lib/legacy';
import { useEffect, useState } from 'react';

export default function useMobile() {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    // const reg = /(iPhone|iPad|iPod|iOS|Android|Linux armv8l|Linux armv7l|Linux aarch64)/i;
    // const temp = reg.test(navigator.platform)

    // const temp = document.body.clientWidth <= 800
    const temp = isMobileDevice(navigator)

    setIsMobile(temp);
  }, [])
  return { isMobile }
}