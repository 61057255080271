import { PageNameType, getServerBaseUrl } from "config/backend";
import { bigNumberify } from "lib/numbers";
import useSWR from "swr";

export function useUserInTotal(chainId) {
  const domain = getServerBaseUrl(chainId, PageNameType.STATS)
  const url = `${domain}/api/user/total?chainId=${chainId}`;

  const { data = { total: '0' } } = useSWR(
    url,
    async (url) => {
      const res = await fetch(url);
      const json = await res.json();
      return {
        total: json.count,
      };
    },
    {
      refreshInterval: 60000,
    }
  );

  return data;
}
