import React, { useState } from "react";

import Modal from "components/Modal/Modal";
import "../StakeV2.css";
import MlpSwap from "pages/Stake/compoonents/Mlp/MlpSwap";
import { t, Trans } from "@lingui/macro";

export default function BuySellMPLModal(props) {
  const { isVisible, setIsVisible, mlpPrice } = props;

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={t`Buy / Sell SLP`}>
        <MlpSwap {...props} mlpPrice={mlpPrice} />
      </Modal>
    </div>
  );
}
