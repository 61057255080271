import { BytesLike, ethers } from 'ethers';

export const EmptyBytes32String = '0x0000000000000000000000000000000000000000000000000000000000000000'

export function parseBytes32String(value: BytesLike = EmptyBytes32String) {
  return ethers.utils.parseBytes32String(value)
}

export function formatBytes32String(value: string = '') {
  return ethers.utils.formatBytes32String(value)
}