import { type } from 'os';
import { ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, MAINNET, ZK_TESTNET } from "./chains";

export const GMX_STATS_API_URL = "https://stats.gmx.io/api";

export enum PageNameType {
  // PRICES = 'prices',
  REFERRALS = 'referrals',
  EMPTY_STRING = '',
  STATS = 'stats',
}

const BACKEND_URLS = {
  default: "https://gmx-server-mainnet.uw.r.appspot.com",

  [MAINNET]: "https://gambit-server-staging.uc.r.appspot.com",
  // [ARBITRUM_TESTNET]: "https://gambit-server-devnet.uc.r.appspot.com",

  [ARBITRUM]: "https://gmx-server-mainnet.uw.r.appspot.com",
  [AVALANCHE]: "https://gmx-avax-server.uc.r.appspot.com",

  [ARBITRUM_TESTNET]: "https://prices.7booster.cc",
  [`${ARBITRUM_TESTNET}_${PageNameType.REFERRALS}`]: `https://referrals.7booster.cc`,
  [`${ARBITRUM_TESTNET}_${PageNameType.STATS}`]: `https://stats.7booster.cc`,

  [ZK_TESTNET]: 'https://price.app.reed.plus',
  [`${ZK_TESTNET}_${PageNameType.REFERRALS}`]: 'https://refer.app.reed.plus',
  [`${ZK_TESTNET}_${PageNameType.STATS}`]: 'https://stats.app.reed.plus',
};


export function getServerBaseUrl(chainId: number, pageName: PageNameType = PageNameType.EMPTY_STRING) {
  if (!chainId) {
    throw new Error("chainId is not provided");
  }

  if (document.location.hostname.includes("deploy-preview")) {
    const fromLocalStorage = localStorage.getItem("SERVER_BASE_URL");
    if (fromLocalStorage) {
      return fromLocalStorage;
    }
  }

  return BACKEND_URLS[`${chainId}${pageName ? `_${pageName}` : PageNameType.EMPTY_STRING}`] || BACKEND_URLS.default;
}

export function getServerUrl(chainId: number, path: string) {
  return `${getServerBaseUrl(chainId)}${path}`;
}

// export function getServerUrlMdca(chainId: number, path: string) {
//   return `${getServerBaseUrl(chainId)}${path}`;
// }