import { useRequest } from 'ahooks';
import { getServerUrl } from 'config/backend';
import { getContract } from 'config/contracts';
import { ethers } from 'ethers';
import { requestGet } from 'lib/api';
import { useState } from 'react';
import MlpManager from 'abis/MlpManager.json'
import { callContract } from 'lib/contracts';
import { PollingInterval } from 'config/utils';
import { expandDecimals } from 'lib/numbers';
import { USD_DECIMALS } from 'lib/legacy';

export default function usePrices(chainId: number,) {
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const [prices, setPrices] = useState({
    [nativeTokenAddress]: '0'
  })
  const indexPricesUrl = getServerUrl(chainId, `/prices`);

  const { run: getPrices } = useRequest(
    () => {
      return requestGet(indexPricesUrl, { network: chainId })
    }, {
    pollingInterval: PollingInterval,
    refreshDeps: [chainId],
    onSuccess: (res) => {
      setPrices({
        ...prices,
        ...res,
      })
    }
  });
  return { prices, getPrices }
}
