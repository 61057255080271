import React, { useState } from "react";
import Footer from "components/Footer/Footer";
import "./Home.scss";
import { Trans } from "@lingui/macro";
import HomeTopImg from "img/home_top.gif";
import HomeTradeImg from "img/home_trade.svg";
import HomeEarnImg from "img/home_earn.svg";
import HomeRebatesImg from "img/home_rebates.svg";
import LogoImg from "img/logo_icon_small.svg";
import GlpImg from "img/glp_icon.svg";
import HomeSecurityImg from "img/home_security.svg";
import Button from "components/Button/Button";
import { SecurityUrl } from "config/links";
import BuyMDCAModal from "pages/Stake/compoonents/BuyMDCAModal";
import BuySellMPLModal from "pages/Stake/compoonents/BuySellMPLModal";
import { useMlpPrice, useGetArrAndSupply } from "lib/useHook";
import { useChainId } from "lib/chains";
import { usePositionInfo, useVolumeInTotal } from "hooks/dashboard";
import { MDCA_DECIMALS, USD_DECIMALS } from "lib/legacy";
import { formatAmount, formatBigAmount } from "lib/numbers";
import { useWeb3React } from "@web3-react/core";
import { useMdcaTotalSupply } from "hooks/useMdca";
import { useMlpTotalSupply } from "hooks/useMlp";
import { useUserInTotal } from "hooks/dashboard/useUser";
import { ethers } from "ethers";

export default function Home({ showRedirectModal, redirectPopupTimestamp, savedSlippageAmount, connectWallet }) {
  const { active, library, account } = useWeb3React();

  const { chainId } = useChainId();

  const { total: totalVolume } = useVolumeInTotal(chainId);
  const { mdcaTotalSupply: totalMdcaSupply } = useMdcaTotalSupply(chainId, library, account);
  const { mlpTotalSupply: mlpSupply } = useMlpTotalSupply(chainId, library, account);
  const { openInterest } = usePositionInfo(chainId);
  const { total: totalUsers } = useUserInTotal(chainId);

  const [isBuyMDCAModalVisible, setIsBuyMDCAModalVisible] = useState(false);
  const [isBuySellModalVisible, setIsBuySellModalVisible] = useState(false);
  const [isBuying, setIsBuying] = useState(true);
  const [mlpPrice] = useMlpPrice();
  const arrAndSupply = useGetArrAndSupply();

  let checkSummedAccount = "";
  if (ethers.utils.isAddress(account)) {
    checkSummedAccount = ethers.utils.getAddress(account);
  }

  const onClickMDCAHandle = () => {
    setIsBuyMDCAModalVisible(true);
  };

  const onClickMPLHandle = () => {
    setIsBuySellModalVisible(true);
  };

  const onClickPrimary = () => {
    if (!active) {
      connectWallet();
      return;
    }
  };

  return (
    <>
      <BuyMDCAModal isVisible={isBuyMDCAModalVisible} setIsVisible={setIsBuyMDCAModalVisible} />
      <BuySellMPLModal
        isVisible={isBuySellModalVisible}
        setIsVisible={setIsBuySellModalVisible}
        mlpPrice={mlpPrice}
        savedSlippageAmount={savedSlippageAmount}
        checkSummedAccount={checkSummedAccount}
        isBuying={isBuying}
        setIsBuying={setIsBuying}
      />
      <div className="Home">
        <div className="Home-top">
          <div className="Home-top-left">
            <span className="Home-top-left-one">
              <Trans>A Trustless Crypto</Trans>
            </span>
            <span className="Home-top-left-two">
              <Trans>Decentralized Exchange</Trans>
            </span>
            <span className="Home-top-left-three">
              <Trans>
                Maximize your trading efficiency with leveraged trading BTC、ETH and more cryptocurrencies are opened.
              </Trans>
            </span>
            <Button className="Home-top-left-button" to="/trade">
              <Trans>Launch App</Trans>
            </Button>
          </div>
          <img src={HomeTopImg} alt="" />
        </div>
        <div className="Home-top-achievement">
          <div className="Home-top-data">
            <span className="Home-top-data-number">${formatAmount(totalVolume, USD_DECIMALS, 0, true)}</span>
            <span>Total Volume</span>
          </div>
          <div className="Home-top-data">
            <span className="Home-top-data-number">${formatAmount(openInterest, USD_DECIMALS, 0, true)}</span>
            <span>Open Interest</span>
          </div>
          <div className="Home-top-data">
            <span className="Home-top-data-number">{totalUsers.toLocaleString()}</span>
            <span>Total Users</span>
          </div>
        </div>
      </div>
      <div className="Home-content-one">
        <div className="Home-content-one-card">
          <img src={HomeTradeImg} alt="" />
          <h1>TRADE</h1>
          <span>Trade the most popular product in crypto in a permissionless way.</span>
          <Button className="Home-content-one-button" to="/trade">
            <Trans>Trade Now</Trans>
          </Button>
        </div>
        <div className="Home-content-one-card">
          <img src={HomeEarnImg} alt="" />
          <h1>EARN</h1>
          <span>Provide liquidity to earn SPP tokens, transaction fees and rewards</span>
          <Button className="Home-content-one-button" to="/earn">
            <Trans>Earn Now</Trans>
          </Button>
        </div>
        <div className="Home-content-one-card">
          <img src={HomeRebatesImg} alt="" />
          <h1>Rebates</h1>
          <span>Broker rebate up to 65% and traders can enjoy up to 70% rate reduction.</span>
          <Button className="Home-content-one-button" to="/referrals">
            <Trans>Get Rebates</Trans>
          </Button>
        </div>
      </div>
      <div className="Home-content-two">
        <span>Two tokens create our ecosystem</span>
        <div className="Home-content-two-card">
          <div className="Home-content-two-card-top">
            <div className="Home-content-two-card-top-left">
              <img src={LogoImg} alt="" />
              <span>SPP</span>
            </div>
            <Button className="Home-content-two-link" to="/earn">
              <u>
                <Trans>More</Trans>
              </u>
            </Button>
          </div>
          <span className="Home-content-two-card-content">
            SPP is the utility and governance token. Accrues 30% of the platform's generated fees.
          </span>
          <div className="Home-content-two-card-grid">
            <span>APR:</span>
            <span className="Home-content-two-card-grid-right">{`${(Number(arrAndSupply.mdcaStakeAPR) * 100).toFixed(
              2
            )}%`}</span>
            <span>Total Supply</span>
            <span className="Home-content-two-card-grid-right">
              {formatBigAmount(totalMdcaSupply, MDCA_DECIMALS, 0, true)}
            </span>
          </div>
          <button className="Home-content-two-button" onClick={onClickMDCAHandle}>
            <Trans>Buy on Dex</Trans>
          </button>
        </div>
        <div className="Home-content-two-card">
          <div className="Home-content-two-card-top">
            <div className="Home-content-two-card-top-left">
              <img src={GlpImg} alt="" />
              <span>SLP</span>
            </div>
            <Button className="Home-content-two-link" to="/earn">
              <u>
                <Trans>More</Trans>
              </u>
            </Button>
          </div>
          <span className="Home-content-two-card-content">
            SLP is the liquidity provider token. Accrues 60% of the platform's generated fees.
          </span>
          <div className="Home-content-two-card-grid">
            <span>APR:</span>
            <span className="Home-content-two-card-grid-right">{`${(Number(arrAndSupply.mlpARP) * 100).toFixed(
              2
            )}%`}</span>
            <span>Total Supply</span>
            <span className="Home-content-two-card-grid-right">{formatBigAmount(mlpSupply, MDCA_DECIMALS, 0, true)}</span>
          </div>
          {active && (<button className="Home-content-two-button" onClick={onClickMPLHandle}>
            <Trans>Mint SLP</Trans>
          </button>)}
          {!active && (
            <Button variant="primary-action Home-content-two-button" onClick={onClickPrimary}>
              <Trans>Connect Wallet</Trans>
            </Button>
          )}
        </div>
      </div>
      <div className="Home-content-three">
        <div className="Home-content-three-left">
          <span className="Home-content-three-left-top">Security</span>
          <span className="Home-content-three-left-middle">
            Our smart contracts have undergone multiple independent audits from slowmist.
          </span>
          <Button className="Home-content-three-left-button" to={SecurityUrl}>
            <Trans>Learn More</Trans>
          </Button>
        </div>
        <img src={HomeSecurityImg} alt="" />
      </div>
      <Footer showRedirectModal={showRedirectModal} redirectPopupTimestamp={redirectPopupTimestamp} />
    </>
  );
}
