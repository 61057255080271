import React, { useState, useMemo } from "react";
import "./Info.css";
import { formatAmount, numberWithCommas, bigNumberify, formatBigAmount } from "lib/numbers";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useChainId } from "lib/chains";
import ImgUserHeadGrey from "img/referrals/user-head-grey.svg";
import ImgUserHead from "img/referrals/user-head.svg";
import ImgUserHeadFire from "img/referrals/user-head-fire.svg";
import InfoBindCodeModal from "./InfoBindCodeModal";
import InfoCreateCodeModal from "./InfoCreateCodeModal";
import { useRequest } from "ahooks";
import { requestPost } from "lib/api";
import Button from "components/Button/Button";
import { t, Trans } from "@lingui/macro";
import { helperToast } from 'lib/helperToast';
import { MDCA_DECIMALS } from 'lib/legacy';

export default function InfoContent(props) {
  const { referralCode, ownersCode, refreshData, userInfo, checkSummedAccount, BaseUrl, connectWallet } = props;
  const { active, library } = useWeb3React();
  const [isBindModalVisible, setIsBindModalVisible] = useState(false);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  const { chainId } = useChainId();

  const { isLoading: claiming, run: onClaim, } = useRequest(
    async () => {
      if (!referralCode || claiming) {
        return {};
      }
      return requestPost(`${BaseUrl}/referrals/user/claim`,{},);
    },
    {
      refreshDeps: [checkSummedAccount, ownersCode, referralCode],
      manual: true,
      onSuccess: (data) => {
        refreshData()
        if (data && data.code === 0) {
          helperToast.success(
            <div>
              <Trans>Claim successed.</Trans>
            </div>
          )
        } else {
          const failMsg = (<Trans>
            <div>Claim failed.</div>
            <div>{data.message}</div>
          </Trans>)
          helperToast.error(failMsg, { autoClose: 5 * 1000 });
        }
      },
      onError: (error) => {
        console.log(1111, error)
      }
    }
  );

  const onLeftButtonClick = () => {
    if (!ownersCode) {
      setIsCreateModalVisible(true);
    }
    if (ownersCode && referralCode) {
      onClaim();
    }
  };

  const CreateCodeButtonLable = t`Create My Invitation Code`;
  const ClaimButtonLabel = claiming ? "Claiming" : "Claim";
  const StatusComponents = useMemo(() => {
    const defaultValue = {
      UserHeadImage: ImgUserHeadGrey,
      UserTitle: "-",
      UserButtonText: CreateCodeButtonLable,
    };
    if (!ownersCode && !referralCode) {
      return defaultValue;
    }
    if (!ownersCode && referralCode) {
      return {
        UserHeadImage: ImgUserHead,
        UserTitle: "Common",
        UserButtonText: CreateCodeButtonLable,
      };
    }
    if (ownersCode && referralCode) {
      return {
        UserHeadImage: ImgUserHeadFire,
        UserTitle: userInfo.tier ? `Tier ${userInfo.tier}` : 'Common',
        UserButtonText: ClaimButtonLabel,
      };
    }
    return defaultValue;
  }, [ownersCode, referralCode, userInfo, claiming]);

  const { UserHeadImage, UserTitle, UserButtonText } = StatusComponents;

  const pendingRewardFee = (userInfo.totalFee - userInfo.claimFee || 0).toString()
  return (
    <React.Fragment>
      <InfoBindCodeModal
        isVisible={isBindModalVisible}
        setIsVisible={setIsBindModalVisible}
        chainId={chainId}
        library={library}
        refreshData={refreshData}
      />
      <InfoCreateCodeModal
        isVisible={isCreateModalVisible}
        setIsVisible={setIsCreateModalVisible}
        chainId={chainId}
        library={library}
        refreshData={refreshData}
      />
      <div className="Exchange-list Orders App-box info-container">
        <div className="left">
          <div className="user-head-img">
            <img src={UserHeadImage} alt="ImgUserHead" />
          </div>
          <div className="user-info">
            <div className="user-tier">{UserTitle}</div>
            <div className="user-info-list">
              <div className="user-info-item">
                <span className="lable"><Trans>Discount Rate</Trans></span>
                <span className="value">{userInfo.discountRate ? `${userInfo.discountRate}%` : "-"}</span>
              </div>
              <div className="user-info-item">
                <span className="lable"><Trans>Commission rate for newly invited users</Trans></span>
                <span className="value">{userInfo.commissionRate ? `${userInfo.commissionRate}%` : "-"}</span>
              </div>
              {ownersCode && referralCode && (
                <div className="user-info-item">
                  <span className="lable"><Trans>Claimable</Trans></span>
                  <span className="value">
                    {(pendingRewardFee || pendingRewardFee === 0)
                      ? `${formatBigAmount(pendingRewardFee, MDCA_DECIMALS, 4)} ETH`
                      : "-"}
                  </span>
                </div>
              )}
            </div>
            {active && (
              <Button className="primary-action handle-code-btn claim-btn create-code-btn" onClick={onLeftButtonClick} disabled={ownersCode && referralCode && (claiming || !pendingRewardFee)}>
                {UserButtonText}
              </Button>
            )}
            {!active && (
              <Button
                variant="primary-action"
                onClick={() => {
                  connectWallet();
                }}
              >
                <Trans>Connect Wallet</Trans>
              </Button>
            )}
          </div>
        </div>
        {!referralCode && active && (
          <div className="right" style={{ alignItems: "flex-end" }}>
            <div className="handle-code-btn bind-code-btn" onClick={() => setIsBindModalVisible(true)}>
              <Trans>Bind Invitation Code</Trans>
            </div>
          </div>
        )}
        {referralCode && (
          <div className="right">
            <div className="floor-top">
              <div className="total-rbates">
                <div className="lable"><Trans>Total Rebates</Trans></div>
                <div className="value">
                  {userInfo.totalFee ? `${userInfo?.totalFee == 0 ? 0 : formatAmount(bigNumberify(userInfo?.totalFee), 18, 4)}ETH` : "-"}
                </div>
              </div>
              <div className="referral-code">
                <div className="lable"><Trans>Active Referral Code</Trans></div>
                <div className="value">{referralCode}</div>
              </div>
            </div>

            {ownersCode && (
              <div className="common-item trader-referred">
                <div className="item">
                  <div className="lable"><Trans>Traders Referred</Trans></div>
                  <div className="value">
                    {userInfo.tradersReferred && Number(userInfo.tradersReferred)
                      ? `${numberWithCommas(userInfo.tradersReferred)}`
                      : "-"}
                  </div>
                </div>
                <div className="item">
                  <div className="lable"><Trans>Active Traders Referred</Trans></div>
                  <div className="value">
                    {userInfo.activeTradersReferred && Number(userInfo.activeTradersReferred)
                      ? `${numberWithCommas(userInfo.activeTradersReferred)}`
                      : "-"}
                  </div>
                </div>
              </div>
            )}

            <div className="common-item trading-volume">
              <div className="item">
                <div className="lable"><Trans>My Trading Volume</Trans></div>
                <div className="value">
                  {userInfo.myTradingVolume && Number(userInfo.myTradingVolume)
                    ? `$ ${formatBigAmount(userInfo.myTradingVolume)}`
                    : "-"}
                </div>
              </div>
              <div className="item">
                <div className="lable"><Trans>Total Trading Volume</Trans></div>
                <div className="value">
                  {userInfo.totalTradingVolume && Number(userInfo.totalTradingVolume)
                    ? `$ ${formatBigAmount(userInfo.totalTradingVolume)}`
                    : "-"}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
