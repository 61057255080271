import { PageNameType, getServerBaseUrl } from "config/backend";
import { bigNumberify } from "lib/numbers";
import useSWR from "swr";

export function useVolumeIn24h(chainId) {
  const domain = getServerBaseUrl(chainId, PageNameType.STATS)
  const url = `${domain}/api/volume/24h?chainId=${chainId}`;

  const { data = {total: '0'} } = useSWR(
    url,
    async (url) => {
      const res = await fetch(url);
      const json = await res.json();
      return {
        total: bigNumberify(json.total),
      };
    },
    {
      refreshInterval: 60000,
    }
  );

  return data;
}

export function useVolumeInTotal(chainId) {
  const domain = getServerBaseUrl(chainId, PageNameType.STATS)
  const url = `${domain}/api/volume/total?chainId=${chainId}`;

  const { data = { total: '0' } } = useSWR(
    url,
    async (url) => {
      const res = await fetch(url);
      const json = await res.json();
      return {
        total: bigNumberify(json.total),
      };
    },
    {
      refreshInterval: 60000,
    }
  );

  return data;
}
