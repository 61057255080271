import React, { useMemo } from "react";
import { t, Trans } from "@lingui/macro";

import "./DetailHistory.css";
import { bigNumberify, formatAmount } from "lib/numbers";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { useWeb3React } from '@web3-react/core';
import sliceAddress from 'lib/slice-address';
import { formatDate, formatDateTime } from 'lib/dates';
import { useChainId } from 'lib/chains';
import { PageNameType, getServerBaseUrl } from 'config/backend';
import { useRequest } from 'ahooks';
import { requestPost } from 'lib/api';
import { PollingInterval } from 'config/utils';
import ImgNoda from 'img/nodata.svg'

export default function DetailHistory(props) {
  const { chainId } = useChainId();
  const { account: userAddress } = useWeb3React();

  const BaseUrl = `${getServerBaseUrl(chainId, PageNameType.REFERRALS)}`;
  const { data } = useRequest(
    async () => {
      return requestPost(`${BaseUrl}/referrals/user/detail`, {
        "pageNum": 1,
        "pageSize": 100
      })
    }, {
    refreshDeps: [userAddress],
    pollingInterval: PollingInterval,
    manual: false,
    // onSuccess: (data) => {
    //   console.log('chf-success-detail', data,)
    // }
  });

  const renderHead = useMemo(() => {
    return (
      <tr className="Exchange-list-header">
        <th>
          <div>
            <Trans>Date</Trans>
          </div>
        </th>
        <th>
          <div>
            <Trans>Type</Trans>
          </div>
        </th>
        <th>
          <div>
            <Trans>Amount</Trans>
          </div>
        </th>
        <th className='small-hide'>
          <div>
            <Trans>Transition</Trans>
          </div>
        </th>
      </tr>
    );
  }, []);

  const renderEmptyRow = useMemo(() => {
    if (data?.data?.dataList?.length) {
      return null;
    }

    return (
      <tr>
        <td colSpan="4" >
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '8rem' }}>
            <img src={ImgNoda} alt='nodata' style={{ marginBottom: '2rem' }} />
            <Trans>No History</Trans>
          </div>
        </td>
      </tr>
    );
  }, [data]);

  const renderLargeList = useMemo(() => {
    if (!data || !data.data || !data.data.dataList || !data.data.dataList.length) {
      return null;
    }
    const content = data.data.dataList.map((row) => {
      return (
        <tr className="Exchange-list-item" key={row.trxHash}>
          <td>
            {formatDate(row.timestamp)}
          </td>
          <td>
            {row.type === 1 ? 'Discount' : 'Commission'}
          </td>
          <td>
            {formatAmount(bigNumberify(row.feeEth), 18)}
          </td>
          <td>
            <ExternalLink href={`https://goerli.explorer.zksync.io/tx/${row.hash}`}>
              {sliceAddress(row.hash)}
            </ExternalLink>
          </td>
        </tr>
      );
    });
    return content;
  }, [data]);

  return (
    <React.Fragment>
      <div style={{width: '100%', overflow: 'scroll'}}>
        <table className="Exchange-list history-list Orders App-box">
          <tbody>
            {renderHead}
            {renderEmptyRow}
            {renderLargeList}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}
