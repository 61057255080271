import React, { useEffect, useState } from "react";
import { Trans, t } from "@lingui/macro";

import Modal from "components/Modal/Modal";
import Checkbox from "components/Checkbox/Checkbox";
import MDCAStakingRouter from "abis/MDCAStakingRouter.json";

import "../StakeV2.css";
import { callContract } from "lib/contracts";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import Button from "components/Button/Button";
import { BigNumber, ethers } from "ethers";
import { useRequest } from "ahooks";
import { getContract } from "config/contracts";

export default function ClaimModal(props) {
  const {
    isVisible,
    setIsVisible,
    library,
    chainId,
    setPendingTxns,
    rewardProofData,
    shouldClaimMDCARewardDisabled,
    shouldClaimEthRewardDisabled,
    sholdEthForMDCAClaimDisabled,
    shouldEthForMLPClaimDisabled,
    sholdMdcaForMDCAClaimDisabled,
    sholdMdcaForMLPClaimDisabled,
  } = props;
  const [isClaiming, setIsClaiming] = useState(false);
  const [shouldClaimEthReward, setShouldClaimEthReward] = useState(false);
  const [shouldClaimMDCAReward, setShouldClaimMDCAReward] = useState(false);

  const isPrimaryEnabled = () => {
    return !isClaiming;
  };

  const getPrimaryText = () => {
    if (isClaiming) {
      return t`Claiming...`;
    }
    return t`Claim`;
  };

  const { run: onBatchClaim } = useRequest(
    () => {
      if (!rewardProofData) {
        return false;
      }
      const {
        mdcaStakeReward,
        mlpReward,
        mdcaStakeETHReward,
        mlpETHReward,
        mdcaStakeRewardProof,
        mlpRewardProof,
        mdcaStakeETHRewardProof,
        mlpETHRewardProof,
      } = rewardProofData;

      const ethForMDCAClaim = {
        action: !sholdEthForMDCAClaimDisabled && shouldClaimEthReward,
        amount: BigNumber.from(mdcaStakeETHReward),
        merkleProof: mdcaStakeETHRewardProof,
      };
      const ethForMLPClaim = {
        action: !shouldEthForMLPClaimDisabled && shouldClaimEthReward,
        amount: BigNumber.from(mlpETHReward),
        merkleProof: mlpETHRewardProof,
      };
      const mdcaForMDCAClaim = {
        action: !sholdMdcaForMDCAClaimDisabled && shouldClaimMDCAReward,
        amount: BigNumber.from(mdcaStakeReward),
        merkleProof: mdcaStakeRewardProof,
      };
      const mdcaForMLPClaim = {
        action: !sholdMdcaForMLPClaimDisabled && shouldClaimMDCAReward,
        amount: BigNumber.from(mlpReward),
        merkleProof: mlpRewardProof,
      };
      const method = "batchClaim";
      const params = [ethForMDCAClaim, ethForMLPClaim, mdcaForMDCAClaim, mdcaForMLPClaim];
      const MDCAStakingRouterAddress = getContract(chainId, "MDCAStakingRouter");
      console.log("chf-onBatchClaim-before:", MDCAStakingRouterAddress, chainId, method, params);
      const contract = new ethers.Contract(MDCAStakingRouterAddress, MDCAStakingRouter, library.getSigner());
      return callContract(chainId, contract, method, params, {
        sentMsg: t`Claim submitted.`,
        failMsg: t`Claim failed.`,
        successMsg: t`Claim completed!`,
        setPendingTxns,
      });
    },
    {
      manual: true,
      refreshDeps: [library, rewardProofData],
      onBefore: () => {
        setIsClaiming(true);
      },
      onSuccess: (data) => {
        console.log("chf-onBatchClaim success", data);
      },
      onError: (error) => {
        console.log("chf-onBatchClaim error", error);
      },
      onFinally: () => {
        setIsClaiming(false);
        setIsVisible(false);
      },
    }
  );

  const onClickPrimary = () => {
    setIsClaiming(true);
    onBatchClaim();
  };

  useEffect(() => {
    if (!isVisible) {
      setShouldClaimEthReward(false);
      setShouldClaimMDCAReward(false);
    }
  }, [isVisible]);

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={t`Claim Rewards`}>
        <div className="CompoundModal-menu">
          <div>
            <Checkbox
              isChecked={shouldClaimMDCAReward}
              setIsChecked={(cheked) => {
                setShouldClaimMDCAReward(cheked);
              }}
              disabled={shouldClaimMDCARewardDisabled}
            >
              <Trans>Claim SPP Rewards</Trans>
            </Checkbox>
          </div>
          <div>
            <Checkbox
              isChecked={shouldClaimEthReward}
              setIsChecked={(cheked) => {
                setShouldClaimEthReward(cheked);
              }}
              disabled={shouldClaimEthRewardDisabled}
            >
              <Trans>Claim ETH Rewards</Trans>
            </Checkbox>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <Button variant="primary-action" className="w-100" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </Button>
        </div>
      </Modal>
    </div>
  );
}
