import React, { useEffect, useState } from "react";
import { Trans, t } from "@lingui/macro";
import Modal from "components/Modal/Modal";
import Checkbox from "components/Checkbox/Checkbox";
import Token from "abis/Token.json";
import useSWR from "swr";

import { getContract } from "config/contracts";
import MDCAStakingRouter from "abis/MDCAStakingRouter.json";

import "../StakeV2.css";
import { callContract, contractFetcher } from "lib/contracts";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { approveTokens } from "domain/tokens";
import Button from "components/Button/Button";
import { BigNumber, ethers } from "ethers";
import { useRequest } from "ahooks";
import { getServerBaseUrl } from "config/backend";
import { requestPost } from "lib/api";

export default function CompoundModal(props) {
  const {
    isVisible,
    setIsVisible,
    // active,
    account,
    library,
    chainId,
    setPendingTxns,
    rewardProofData,
    shouldClaimMDCARewardDisabled,
    shouldClaimEthRewardDisabled,
    sholdEthForMDCAClaimDisabled,
    shouldEthForMLPClaimDisabled,
    sholdMdcaForMDCAClaimDisabled,
    sholdMdcaForMLPClaimDisabled,
  } = props;
  const [isCompounding, setIsCompounding] = useState(false);
  const [shouldClaimEthReward, setShouldClaimEthReward] = useState(false);
  const [shouldClaimMDCAReward, setShouldClaimMDCAReward] = useState(false);

  // const gmxAddress = getContract(chainId, "GMX");
  // const stakedGmxTrackerAddress = getContract(chainId, "StakedGmxTracker");

  // const [isApproving, setIsApproving] = useState(false);

  // const { data: tokenAllowance } = useSWR(
  //   active && [active, chainId, gmxAddress, "allowance", account, stakedGmxTrackerAddress],
  //   {
  //     fetcher: contractFetcher(library, Token),
  //   }
  // );

  // const needApproval = shouldStakeGmx && tokenAllowance && totalVesterRewards && totalVesterRewards.gt(tokenAllowance);
  // const needApproval = false

  const isPrimaryEnabled = () => {
    // return !isCompounding && !isApproving && !isCompounding;
    return !isCompounding && !isCompounding;
  };

  const getPrimaryText = () => {
    // if (isApproving) {
    //   return t`Approving GMX...`;
    // }
    // if (needApproval) {
    //   return t`Approve GMX`;
    // }
    if (isCompounding) {
      return t`Compounding...`;
    }
    return t`Compound`;
  };

  const { run: stakeBoostPoint } = useRequest(
    () => {
      const EarnBaseUrl = `${getServerBaseUrl(chainId)}`;
      return requestPost(`${EarnBaseUrl}/stake-boost-point`, { account });
    },
    {
      refreshDeps: [account],
      manual: true,
      onSuccess: (data) => {
        console.log("chf-success-stakeBoostPoint", data);
      },
      onError: (error) => {
        console.log("chf-error-stakeBoostPoint", error);
      },
    }
  );

  const { run: onBatcCompound } = useRequest(
    () => {
      if (!rewardProofData) {
        return false;
      }
      const {
        mdcaStakeReward,
        mlpReward,
        mdcaStakeETHReward,
        mlpETHReward,
        mdcaStakeRewardProof,
        mlpRewardProof,
        mdcaStakeETHRewardProof,
        mlpETHRewardProof,
      } = rewardProofData;

      const ethForMDCAClaim = {
        action: !sholdEthForMDCAClaimDisabled && shouldClaimEthReward,
        amount: BigNumber.from(mdcaStakeETHReward),
        merkleProof: mdcaStakeETHRewardProof,
      };
      const ethForMLPClaim = {
        action: !shouldEthForMLPClaimDisabled && shouldClaimEthReward,
        amount: BigNumber.from(mlpETHReward),
        merkleProof: mlpETHRewardProof,
      };
      const mdcaForMDCAClaim = {
        action: !sholdMdcaForMDCAClaimDisabled && shouldClaimMDCAReward,
        amount: BigNumber.from(mdcaStakeReward),
        merkleProof: mdcaStakeRewardProof,
      };
      const mdcaForMLPClaim = {
        action: !sholdMdcaForMLPClaimDisabled && shouldClaimMDCAReward,
        amount: BigNumber.from(mlpReward),
        merkleProof: mlpRewardProof,
      };
      const method = "compound";
      const params = [ethForMDCAClaim, ethForMLPClaim, mdcaForMDCAClaim, mdcaForMLPClaim];
      const MDCAStakingRouterAddress = getContract(chainId, "MDCAStakingRouter");
      console.log("chf-onBatcCompound-before:", MDCAStakingRouterAddress, chainId, method, params);
      const contract = new ethers.Contract(MDCAStakingRouterAddress, MDCAStakingRouter, library.getSigner());
      return callContract(chainId, contract, method, params, {
        sentMsg: t`Compound submitted!`,
        failMsg: t`Compound failed.`,
        successMsg: t`Compound completed!`,
        setPendingTxns,
      });
    },
    {
      manual: true,
      refreshDeps: [library, rewardProofData],
      onSuccess: (data) => {
        console.log("chf-success-onBatcCompound ", data);
      },
      onError: (error) => {
        console.log("chf-error-onBatcCompound ", error);
      },
    }
  );

  const onClickPrimary = () => {
    // if (needApproval) {
    //   approveTokens({
    //     setIsApproving,
    //     library,
    //     tokenAddress: gmxAddress,
    //     spender: stakedGmxTrackerAddress,
    //     chainId,
    //   });
    //   return;
    // }

    setIsCompounding(true);
    Promise.all([stakeBoostPoint(), onBatcCompound()])
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setIsVisible(false);
        setIsCompounding(false);
      });
  };

  useEffect(() => {
    if (!isVisible) {
      setShouldClaimEthReward(false);
      setShouldClaimMDCAReward(false);
    }
  }, [isVisible]);

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={t`Compound Rewards`}>
        <div className="CompoundModal-menu">
          <div>
            <Checkbox isChecked={true} disabled={true}>
              <Trans>Stake Boost Points</Trans>
            </Checkbox>
          </div>
          <div>
            <Checkbox
              isChecked={shouldClaimMDCAReward}
              setIsChecked={(cheked) => {
                setShouldClaimMDCAReward(cheked);
              }}
              disabled={shouldClaimMDCARewardDisabled}
            >
              <Trans>Claim SPP Rewards</Trans>
            </Checkbox>
          </div>
          <div>
            <Checkbox
              isChecked={shouldClaimMDCAReward}
              setIsChecked={(cheked) => {
                setShouldClaimMDCAReward(cheked);
              }}
              disabled={shouldClaimMDCARewardDisabled}
            >
              <Trans>Stake SPP Rewards</Trans>
            </Checkbox>
          </div>
          <div>
            <Checkbox
              isChecked={shouldClaimEthReward}
              setIsChecked={(cheked) => {
                setShouldClaimEthReward(cheked);
              }}
              disabled={shouldClaimEthRewardDisabled}
            >
              <Trans>Claim ETH Rewards</Trans>
            </Checkbox>
          </div>
          <div>
            <Checkbox
              isChecked={shouldClaimEthReward}
              setIsChecked={(cheked) => {
                setShouldClaimEthReward(cheked);
              }}
              disabled={shouldClaimEthRewardDisabled}
            >
              <Trans>Buy SLP With ETH Rewards</Trans>
            </Checkbox>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <Button variant="primary-action" className="w-100" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </Button>
        </div>
      </Modal>
    </div>
  );
}
