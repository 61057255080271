import { ethers } from "ethers";
import { ARBITRUM_TESTNET, ZK_TESTNET } from "./chains";

const { AddressZero } = ethers.constants;

export const XGMT_EXCLUDED_ACCOUNTS = [
  "0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5",
  "0xd9b1c23411adbb984b1c4be515fafc47a12898b2",
  "0xa633158288520807f91ccc98aa58e0ea43acb400",
  "0xffd0a93b4362052a336a7b22494f1b77018dd34b",
];

export const NATIVE_TOKENS = {
  [ARBITRUM_TESTNET]: '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9',
  [ZK_TESTNET]: '0x197A549Af6E6134A5963a9dF68642f45E8F2d0a3',
}

const CONTRACTS = {
  [ARBITRUM_TESTNET]: {
    // sepolia testnet
    USDC: "0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512",
    Vault: "0xc5a5C42992dECbae36851359345FE25997F5C42d",
    Router: "0xE6E340D132b5f46d1e472DebcD681B2aBc16e57E",
    VaultReader: "0x5bf5b11053e734690269C6B9D438F8C9d48F528A",
    Reader: "0x0E801D84Fa97b50751Dbf25036d067dCf18858bF",

    RewardRouter: "0x0000000000000000000000000000000000000000",

    NATIVE_TOKEN: NATIVE_TOKENS[ARBITRUM_TESTNET],
    USDG: "0x67d269191c92Caf3cD7723F116c85e6E9bf55933",

    OrderBook: "0x1429859428C0aBc9C2C47C8Ee9FBaf82cFA0F20f",
    OrderBookReader: "0xab16A69A5a8c12C732e0DEFF4BE56A70bb64c926",

    PositionRouter: "0x1c85638e118b37167e9298c2268758e058DdfDA0",
    PositionManager: "0xCace1b78160AE76398F486c8a18044da0d66d86D",

    ReferralStorage: "0x4631BCAbD6dF18D94796344963cB60d44a4136b6", //

    MDCA: '0x1f10F3Ba7ACB61b2F50B9d6DdCf91a6f787C0E82',
    VeMDCA: '0x38a024C0b412B9d1db8BC398140D00F5Af3093D4',
    MDCAStakingPool: '0x99dBE4AEa58E518C50a1c04aE9b48C9F6354612f',
    MDCAStakingRouter: '0x19cEcCd6942ad38562Ee10bAfd44776ceB67e923',
    DecreaseOrderStorage: '0x870526b7973b56163a6997bB7C886F5E4EA53638',
    "ETHRewardDistributor": "0x2a810409872AfC346F9B5b26571Fd6eC42EA4849",
    "ETHRewardDistributorForMLP": "0xb9bEECD1A582768711dE1EE7B0A1d582D9d72a6C",
    "TokenRewardDistributor": "0x8A93d247134d91e0de6f96547cB0204e5BE8e5D8",
    "TokenRewardDistributorForMLP": "0xF32D39ff9f6Aa7a7A64d7a4F00a54826Ef791a55",
    MLP: "0x18E317A7D70d8fBf8e6E893616b52390EbBdb629",
    MlpManager: "0x4b6aB5F819A515382B0dEB6935D793817bB4af28",
    BalanceUpdater: "0xffa7CA1AEEEbBc30C874d32C7e22F052BbEa0429",
    PositionRouterReader: '0xE3011A37A904aB90C8881a99BD1F6E21401f1522',
    MLPStakingPool: '0xB0f05d25e41FbC2b52013099ED9616f1206Ae21B',
    "StakedMlpTracker": '0x0000000000000000000000000000000000000000',
  },
  [ZK_TESTNET]: {
    // zk testnet
    USDC: "0x55f22702FD15FB0717dbEfb72CdF67bc3948C4dA",
    Vault: "0x031c3B9bf2b979B9aa5a325aA8ae9E038B5e7289",
    Router: "0x525Dc9AdA1dB01FA58e23C406D9FdeB839e7617b",
    VaultReader: "0x27f0FBab953edd4a8F2fD7bFed22C2f76EcBd05a",
    Reader: "0xA4d695b1b89e079B7D6a61a4D0e8849a2ab0dBd1",

    RewardRouter: "0x0000000000000000000000000000000000000000",

    NATIVE_TOKEN: NATIVE_TOKENS[ZK_TESTNET],
    USDG: "0x17Bc67ECbb39150B8014e6e5bFf6eB9D79Fa62f0",

    OrderBook: "0xc21f755c1BB5e0F5fCb89C5b6634F01F3d9eaa65",
    OrderBookReader: "0x64aAB46f5c48d3a7CBDd2A70f6F476bE873C04C2",

    PositionRouter: "0x689E61AbCf10668fB7737a98e7A159E578837Eb2",
    PositionManager: "0xFFA77D8621d5c67b1fdFDD3F41814Cb15b4715d6",

    ReferralStorage: "0xa50d615BaB9F17b4dD70a0D810825478c018F5F8",
    ReferralReader: "0x0000000000000000000000000000000000000000",

    MDCA: '0xF2e4e13D6E300Fd46f5d53a96E41A3CeC2CCdeAa',
    VeMDCA: '0xe7fe68dC6470369CCAb163aA1A90619eb3432692',
    MDCAStakingPool: '0x17C0E825298C4AdaA81A7de2aFE70a338d05266c',
    MDCAStakingRouter: '0x27C937bE10cbB8274718dc128c76CC6f7C160D33',

    DecreaseOrderStorage: '0xa7443Da86469DA0A70F56b9Faf94C96e05F7EACB',

    "ETHRewardDistributor": "0xD4a739DBeE0039EcD526e840d61Be3Ee365F2f9B",
    "ETHRewardDistributorForMLP": "0xE4852f462ac9E3F6F952938BB97aDE3b1D20a696",
    "TokenRewardDistributor": "0x1a85fBc9878fA2F81b6e2Ca6dDAB3E8BD072E0fc",
    "TokenRewardDistributorForMLP": "0x7117e0B43fab7ff20cc7E052416b2f5a16932c50",

    MLP: "0x300d661b6e5ce7319390477cA0FA71bE43a2c9EC",
    MlpManager: "0xD26EaF5003e503D31c9CB268c50A25CF23E7946e",
    BalanceUpdater: "0x09Ba5A7F0DF6A867c9F467009fa0006d4Db56C1a",
    PositionRouterReader: '0x34ECA18eCa7B341fC579A4a30C28400C4258d6E1',
    MLPStakingPool: '0x0A1CA2e67bEB22994135ad4CCf2073faFD47E4B4',
    "StakedMlpTracker": '0x0000000000000000000000000000000000000000',
  },
};

export function getContract(chainId: number, name: string): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}
