import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { Trans, t } from "@lingui/macro";
import useSWR from "swr";
import TooltipComponent from "components/Tooltip/Tooltip";
import "./DashboardV2.scss";
import { MDCA_DECIMALS, MLP_DECIMALS, BASIS_POINTS_DIVISOR, getPageTitle } from "lib/legacy";
import { getContract } from "config/contracts";
import VaultV2 from "abis/VaultV2.json";
import MlpManager from "abis/MlpManager.json";
import Footer from "components/Footer/Footer";
import SEO from "components/Common/SEO";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { getChainName } from "config/chains";
import { contractFetcher } from "lib/contracts";
import { useInfoTokens } from "domain/tokens";
import { getTokenBySymbol, getWhitelistedTokens } from "config/tokens";
import { bigNumberify, expandDecimals, formatAmount } from "lib/numbers";
import { useChainId } from "lib/chains";
import { getIcons } from "config/icons";
import DataCard from "./components/DataCard";
import TokenTable from "./components/TokenTable";
import TokenTableMobile from "./components/TokenTableMobile";
import TokenStats from "./components/TokenStats";
import { useMlpPrice, useMlpTotalSupply } from "hooks/useMlp";
import { useMdcaPrice, useMdcaTotalStaked, useMdcaTotalSupply } from "hooks/useMdca";
import { useVolumeIn24h, useVolumeInTotal, useTotalFee, usePositionInfo, usePeriodFee } from "hooks/dashboard";
import { formatDate } from "lib/dates";
import { useUserInTotal } from "hooks/dashboard/useUser";

export default function DashboardV2() {
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const chainName = getChainName(chainId);
  const currentIcons = getIcons(chainId);

  const { total: totalVolumeIn24 } = useVolumeIn24h(chainId);
  const { total: totalVolumeInTotal } = useVolumeInTotal(chainId);
  const { total: totalFee } = useTotalFee(chainId);
  const { fee: currentFees, timestamp: feesSince } = usePeriodFee(chainId);
  const { longOpenInterest, shortOpenInterest, openInterest } = usePositionInfo(chainId);
  const { total: totalUsers } = useUserInTotal(chainId);

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);
  const visibleTokens = tokenList.filter((t) => !t.isTempHidden);

  const vaultAddress = getContract(chainId, "Vault");
  const mlpManagerAddress = getContract(chainId, "MlpManager");

  const { data: aums } = useSWR([`Dashboard:getAums:${active}`, chainId, mlpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, MlpManager),
  });

  const { data: totalTokenWeights } = useSWR(
    [`GlpSwap:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
    {
      fetcher: contractFetcher(library, VaultV2),
    }
  );

  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);

  const eth = infoTokens[getTokenBySymbol(chainId, "ETH").address];

  const { platformTokenPrices: mdcaPrice } = useMdcaPrice(chainId, library, account);
  const { mdcaTotalStaked: totalStakedMdca } = useMdcaTotalStaked(chainId, library, account);
  const { mdcaTotalSupply: totalMdcaSupply } = useMdcaTotalSupply(chainId, library, account);

  const mdcaMarketCap = useMemo(() => {
    return mdcaPrice.mul(totalMdcaSupply).div(expandDecimals(1, MDCA_DECIMALS));
  }, [mdcaPrice, totalMdcaSupply]);

  const stakedMdcaSupplyUsd = useMemo(() => {
    return mdcaPrice.mul(totalStakedMdca).div(expandDecimals(1, MDCA_DECIMALS));
  }, [mdcaPrice, totalStakedMdca]);

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { mlpPrice } = useMlpPrice(chainId, library, account);
  const { mlpTotalSupply: mlpSupply } = useMlpTotalSupply(chainId, library, account);
  const mlpMarketCap = useMemo(() => {
    return mlpPrice.mul(mlpSupply).div(expandDecimals(1, MLP_DECIMALS));
  }, [mlpPrice, mlpSupply]);

  let tvl;
  if (mlpMarketCap && mdcaPrice && totalStakedMdca) {
    tvl = mlpMarketCap.add(mdcaPrice.mul(totalStakedMdca).div(expandDecimals(1, MDCA_DECIMALS)));
  }

  const ethFloorPriceFund = expandDecimals(350 + 148 + 384, 18);
  const mlpFloorPriceFund = expandDecimals(660001, 18);
  const usdcFloorPriceFund = expandDecimals(784598 + 200000, 30);

  let totalFloorPriceFundUsd;

  if (eth && eth.contractMinPrice && mlpPrice) {
    const ethFloorPriceFundUsd = ethFloorPriceFund.mul(eth.contractMinPrice).div(expandDecimals(1, eth.decimals));
    const mlpFloorPriceFundUsd = mlpFloorPriceFund.mul(mlpPrice).div(expandDecimals(1, 18));

    totalFloorPriceFundUsd = ethFloorPriceFundUsd.add(mlpFloorPriceFundUsd).add(usdcFloorPriceFund);
  }

  let adjustedUsdgSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
    }
  }

  const getWeightText = (tokenInfo) => {
    if (
      !tokenInfo.weight ||
      !tokenInfo.usdgAmount ||
      !adjustedUsdgSupply ||
      adjustedUsdgSupply.eq(0) ||
      !totalTokenWeights
    ) {
      return "...";
    }

    const currentWeightBps = tokenInfo.usdgAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdgSupply);
    // use add(1).div(10).mul(10) to round numbers up
    const targetWeightBps = tokenInfo.weight.mul(BASIS_POINTS_DIVISOR).div(totalTokenWeights).add(1).div(10).mul(10);

    const weightText = `${formatAmount(currentWeightBps, 2, 2, false)}% / ${formatAmount(
      targetWeightBps,
      2,
      2,
      false
    )}%`;

    return (
      <TooltipComponent
        handle={weightText}
        position="right-bottom"
        renderContent={() => {
          return (
            <>
              <StatsTooltipRow
                label={t`Current Weight`}
                value={`${formatAmount(currentWeightBps, 2, 2, false)}%`}
                showDollar={false}
              />
              <StatsTooltipRow
                label={t`Target Weight`}
                value={`${formatAmount(targetWeightBps, 2, 2, false)}%`}
                showDollar={false}
              />
              <br />
              {currentWeightBps.lt(targetWeightBps) && (
                <div style={{ color: "#6F6F6F" }}>
                  <Trans>
                    {tokenInfo.symbol} is below its target weight.
                    <br />
                    <br />
                    Get lower fees to{" "}
                    <Link to="/earn" target="_blank" rel="noopener noreferrer" style={{ color: "#627EEA" }}>
                      buy SLP
                    </Link>{" "}
                    with {tokenInfo.symbol}, and to{" "}
                    <Link to="/earn" target="_blank" rel="noopener noreferrer" style={{ color: "#627EEA" }}>
                      swap
                    </Link>{" "}
                    {tokenInfo.symbol} for other tokens.
                  </Trans>
                </div>
              )}
              {currentWeightBps.gt(targetWeightBps) && (
                <div style={{ color: "#6F6F6F" }}>
                  <Trans>
                    {tokenInfo.symbol} is above its target weight.
                    <br />
                    <br />
                    Get lower fees to{" "}
                    <Link to="/earn" target="_blank" rel="noopener noreferrer" style={{ color: "#627EEA" }}>
                      swap
                    </Link>{" "}
                    tokens for {tokenInfo.symbol}.
                  </Trans>
                </div>
              )}
              <br />
              <div>
                {/* <ExternalLink href="https://gmxio.gitbook.io/gmx/mlp">
                  <Trans>More Info</Trans>
                </ExternalLink> */}
              </div>
            </>
          );
        }}
      />
    );
  };

  let stakedPercent = 0;

  if (totalMdcaSupply && !totalMdcaSupply.isZero() && !totalStakedMdca.isZero()) {
    stakedPercent = totalStakedMdca.mul(100).div(totalMdcaSupply).toNumber();
  }

  // let liquidityPercent = 0;

  // if (totalMdcaSupply && !totalMdcaSupply.isZero() && totalMdcaInLiquidity) {
  //   liquidityPercent = totalMdcaInLiquidity.mul(100).div(totalMdcaSupply).toNumber();
  // }

  let notStakedPercent = 100 - stakedPercent;

  let mdcaDistributionData = [
    {
      name: t`staked`,
      value: stakedPercent,
      color: "#4353fa",
    },
    // {
    //   name: t`in liquidity`,
    //   value: liquidityPercent,
    //   color: "#0598fa",
    // },
    {
      name: t`not staked`,
      value: notStakedPercent,
      color: "#5c0af5",
    },
  ];

  let stableMlp = 0;
  let totalMlp = 0;

  let mlpPool = tokenList.map((token) => {
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo.usdgAmount && adjustedUsdgSupply && adjustedUsdgSupply.gt(0)) {
      const currentWeightBps = tokenInfo.usdgAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdgSupply);
      if (tokenInfo.isStable) {
        stableMlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
      }
      totalMlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
      return {
        fullname: token.name,
        name: token.symbol,
        value: parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`),
      };
    }
    return null;
  });

  let stablePercentage = totalMlp > 0 ? ((stableMlp * 100) / totalMlp).toFixed(2) : "0.0";

  mlpPool = mlpPool.filter(function (element) {
    return element !== null;
  });

  mlpPool = mlpPool.sort(function (a, b) {
    if (a.value < b.value) return 1;
    else return -1;
  });

  mdcaDistributionData = mdcaDistributionData.sort(function (a, b) {
    if (a.value < b.value) return 1;
    else return -1;
  });

  // const statsLink = getStatsLink(chainId);
  return (
    <SEO title={getPageTitle("Dashboard")}>
      <div className="default-container DashboardV2 page-layout">
        <div className="section-title-block">
          <div className="section-title-icon"></div>
          <div className="section-title-content">
            <div className="Page-title">
              <Trans>Stats</Trans> <img width="24" src={currentIcons.network} alt="Network Icon" />
            </div>
            {/* <div className="Page-description">
              For detailed stats:
              {chainId === ARBITRUM_TESTNET && <ExternalLink href={statsLink}>{statsLink}</ExternalLink>}.
            </div> */}
          </div>
        </div>
        <div className="DashboardV2-content">
          <div className="DashboardV2-cards data-overview-cards">
            <DataCard
              title={"AUM"}
              value={tvl}
              renderContent={() => (
                <span>{t`Assets Under Management: SPP staked (All chains) + SLP pool (${chainName}).`}</span>
              )}
            />
            <DataCard
              title={"SLP Pool"}
              value={aum}
              renderContent={() => (
                <Trans>
                  <p>Total value of tokens in SLP pool ({chainName}).</p>
                  <p>Other websites may show a higher value as they add positions' collaterals to the SLP pool.</p>
                </Trans>
              )}
            />
            <DataCard title={"24h Volume"} value={totalVolumeIn24} />
            <DataCard title={"Open Interest"} value={openInterest} />
            <DataCard title={"Long Positions"} value={longOpenInterest} />
            <DataCard title={"Short Positions"} value={shortOpenInterest} />
            <DataCard title={`Fees since ${formatDate(feesSince)}`} value={currentFees} />
            <DataCard title={"Total Fees"} value={totalFee} />
            <DataCard title={"Total Users"} value={totalUsers} prefix="" handleValue={(value) => value} />
            <DataCard title={"Total Volume"} value={totalVolumeInTotal} />
            <div style={{ width: "32rem" }} />
            <div style={{ width: "32rem" }} />
          </div>
          <div className="Tab-title-section">
            <div className="Page-title">
              <Trans>Tokens</Trans> <img src={currentIcons.network} width="24" alt="Network Icon" />
            </div>
            <div className="Page-description">
              <Trans>Platform and SLP index tokens.</Trans>
            </div>
          </div>
          <div className="DashboardV2-token-cards">
            <TokenStats
              currentIcons={currentIcons}
              mdcaPrice={mdcaPrice}
              totalMdcaSupply={totalMdcaSupply}
              stakedMdcaSupplyUsd={stakedMdcaSupplyUsd}
              mdcaMarketCap={mdcaMarketCap}
              mdcaDistributionData={mdcaDistributionData}
              mlpPrice={mlpPrice}
              mlpSupply={mlpSupply}
              mlpMarketCap={mlpMarketCap}
              stablePercentage={stablePercentage}
              mlpPool={mlpPool}
            />
            <TokenTable
              currentIcons={currentIcons}
              visibleTokens={visibleTokens}
              infoTokens={infoTokens}
              getWeightText={getWeightText}
            />
            <TokenTableMobile visibleTokens={visibleTokens} infoTokens={infoTokens} getWeightText={getWeightText} />
          </div>
        </div>
        <Footer />
      </div>
    </SEO>
  );
}
