import { ARBITRUM_TESTNET, ZK_TESTNET } from "config/chains";
import gmxIcon from "img/ic_gmx_40.svg";
import glpIcon from "img/ic_glp_40.svg";
import MDCALogo from "img/logo_icon_small.svg";
import zksyncImg from "img/chains/zksync.png";
import mlpIcon from 'img/glp_icon.svg'

const ICONS = {
  [ARBITRUM_TESTNET]: {
    network: MDCALogo,
    mdca: MDCALogo,
    mlp: mlpIcon
  },
  [ZK_TESTNET]: {
    network: zksyncImg,
    mdca: MDCALogo,
    mlp: mlpIcon
  },
  common: {
    gmx: gmxIcon,
    glp: glpIcon,
  },
};

export function getIcon(chainId: number | "common", label: string) {
  if (chainId in ICONS) {
    if (label in ICONS[chainId]) {
      return ICONS[chainId][label];
    }
  }
}
export function getIcons(chainId: number | "common") {
  if (!chainId) return;
  if (chainId in ICONS) {
    return ICONS[chainId];
  }
}
