
import { Trans, t } from "@lingui/macro";
import Tooltip from "components/Tooltip/Tooltip";
import {
  BASIS_POINTS_DIVISOR,
  DEFAULT_MAX_USDG_AMOUNT,
  USD_DECIMALS,
  importImage,
} from "lib/legacy";
import { bigNumberify, formatAmount, formatKeyAmount } from 'lib/numbers';
import AssetDropdown from './AssetDropdown';
import StatsTooltipRow from 'components/StatsTooltip/StatsTooltipRow';

export default function TokenTableMobile(props) {
  const {
    visibleTokens = [],
    infoTokens = {},
    getWeightText
  } = props;

  return (
    <div className="token-grid">
      {visibleTokens.map((token) => {
        const tokenInfo = infoTokens[token.address];
        let utilization = bigNumberify(0);
        if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
          utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount);
        }
        let maxUsdgAmount = DEFAULT_MAX_USDG_AMOUNT;
        if (tokenInfo.maxUsdgAmount && tokenInfo.maxUsdgAmount.gt(0)) {
          maxUsdgAmount = tokenInfo.maxUsdgAmount;
        }

        const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_24.svg");
        return (
          <div className="App-card card-container" key={token.symbol}>
            <div className="App-card-title App-card-title-mobile">
              <div className="mobile-token-card">
                <img src={tokenImage} alt={token.symbol} width="20px" />
                <div className="token-symbol-text">{token.symbol}</div>
                {/* <div>
                  <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                </div> */}
              </div>
            </div>
            <div className="App-card-divider"></div>
            <div className="App-card-content">
              <div className="App-card-row">
                <div className="label">
                  <Trans>Price</Trans>
                </div>
                <div>${formatKeyAmount(tokenInfo, "minPrice", USD_DECIMALS, 2, true)}</div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <Trans>Pool</Trans>
                </div>
                <div>
                  <Tooltip
                    handle={`$${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 0, true)}`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <>
                          <StatsTooltipRow
                            label={t`Pool Amount`}
                            value={`${formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 0, true)} ${token.symbol
                              }`}
                            showDollar={false}
                          />
                          <StatsTooltipRow
                            label={t`Target Min Amount`}
                            value={`${formatKeyAmount(tokenInfo, "bufferAmount", token.decimals, 0, true)} ${token.symbol
                              }`}
                            showDollar={false}
                          />
                          <StatsTooltipRow
                            label={t`Max ${tokenInfo.symbol} Capacity`}
                            value={formatAmount(maxUsdgAmount, 18, 0, true)}
                          />
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <Trans>Weight</Trans>
                </div>
                <div>{getWeightText(tokenInfo)}</div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <Trans>Utilization</Trans>
                </div>
                <div>{formatAmount(utilization, 2, 2, false)}%</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  )
}