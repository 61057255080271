import { useWeb3React } from "@web3-react/core";
import { useRequest } from "ahooks";
import { getContract } from "../config/contracts";
import { ethers } from "ethers";
import MlpManager from "../abis/MlpManager.json";
import { callContract } from "./contracts";
import { useChainId } from "./chains";
import { requestGet } from "./api";
import { getServerBaseUrl } from "../config/backend";
import MLP from "../abis/MLP.json";
import MDCA from "../abis/MDCA.json";
import { getCheckSummedAccount } from 'utils';

export const useMlpPrice = () => {
  const { library, account } = useWeb3React()
  const { chainId } = useChainId()

  let checkSummedAccount = "";
  if (ethers.utils.isAddress(account || '')) {
    checkSummedAccount = ethers.utils.getAddress(account || '');
  }
  const { data: mlpPrice = '1', run: getPriceFun } = useRequest((): any => {
    if (!checkSummedAccount || !library) {
      return '1';
    }
    const address = getContract(chainId, "MlpManager");
    const contract = new ethers.Contract(address, MlpManager.abi, library.getSigner());
    return callContract(chainId, contract, "getPrice", [true], {
      hideSuccessMsg: true,
      hideErrorMsg: true,
    })
  }, {
    // manual: true,
    refreshDeps: [checkSummedAccount, library],
    onSuccess: (data) => {
      // console.log('chf-success-mlpPrice', data.toString())
    },
    onError: (error) => {
      // console.log('chf-error-mlpPrice', error)
    }
  })

  return [mlpPrice]
}

export const useGetArrAndSupply = () => {
  const { library, account } = useWeb3React()
  const { chainId } = useChainId()

  let checkSummedAccount = getCheckSummedAccount(account)

  const defaultEarnData = {
    "mdcaStakeAmount": "0",  //mdca总质押数量 带精度展开 比如 1 mdca 这里返回 1000 000 000 000 000 000 000
    "mlpAmount": "0",        //mlp数量
    "boostPointStakeAmount": "0", // boostPoint 质押数量
    "boostPointPendingAmount": "0", // boostPoint 待领取数量
    "boostPointAPR": 1,            // boostPoint 质押apr
    "mdcaStakeReward": "0",       // mdca质押 给与的mdca奖励数量
    "mdcaStakeEthReward": "0",    // mdca质押 给与的eth奖励数量
    "mlpReward": "0",             // mlp持有者 mdca奖励数量
    "mlpEthReward": "0",          // mlp持有至 eth奖励数量
    "totalReward": "0",           // 奖励的总mdca数量  待领取的数量 需要用这个值-从合约里取到的已领取数量
    "totalEthReward": "0",         // 奖励的总eth数量
    "mdcaStakeAPR": "0",          // mdca质押apr 小数 非%
    "mlpARP": "0",                 // mlp质押apr 同上
    mdcaPrice: '1',
  }

  const EarnBaseUrl = `${getServerBaseUrl(chainId)}`
  const { data: { data: earnData } = { data: defaultEarnData } } = useRequest((): any => {
    return requestGet(`${EarnBaseUrl}/earn-info`, { account: checkSummedAccount })
  }, {
    refreshDeps: [checkSummedAccount],
    manual: false,
    // onSuccess: (data) => {
    //   console.log('earnData', data,)
    // }
  })

  const { data: totalSupplyVolumnForMDCA = '0' } = useRequest((): any => {
    if (!checkSummedAccount || !library) {
      return '0';
    }
    const address = getContract(chainId, "MDCA");
    const contract = new ethers.Contract(address, MDCA.abi, library?.getSigner());
    return callContract(chainId, contract, "totalSupply", [], {
      hideSuccessMsg: true,
      hideErrorMsg: true,
    })
  }, {
    // manual: true,
    refreshDeps: [checkSummedAccount, library],
    onSuccess: (data) => {
      // console.log('chf-totalSupplyVolumnForMDCA success', data.toString())
    },
  })

  const { data: totalSupplyVolumnForMLP = '0' } = useRequest((): any => {
    if (!checkSummedAccount || !library) {
      return '0';
    }
    const address = getContract(chainId, "MLP");
    const contract = new ethers.Contract(address, MLP.abi, library.getSigner());
    return callContract(chainId, contract, "totalSupply", [], {
      hideSuccessMsg: true,
      hideErrorMsg: true,
    })
  }, {
    // manual: true,
    refreshDeps: [checkSummedAccount, library],
    onSuccess: (data) => {
      // console.log('chf-totalSupplyVolumnForMLP success', data.toString())
    },
  })

  return { ...earnData, totalSupplyVolumnForMDCA, totalSupplyVolumnForMLP }
}


