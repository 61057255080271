import { PageNameType, getServerBaseUrl } from "config/backend";
import { bigNumberify } from "lib/numbers";
import useSWR from "swr";

export function useTotalFee(chainId) {
  const domain = getServerBaseUrl(chainId, PageNameType.STATS)
  const url = `${domain}/api/fee/total?chainId=${chainId}`;

  const { data = {total: '0'} } = useSWR(
    url,
    async (url) => {
      const res = await fetch(url);
      const json = await res.json();
      return {
        total: bigNumberify(json.total),
      };
    },
    {
      refreshInterval: 60000,
    }
  );

  return data;
}

export function usePeriodFee(chainId) {
  const domain = getServerBaseUrl(chainId, PageNameType.STATS)
  const url = `${domain}/api/fee/period?chainId=${chainId}`;

  const defaultValue = {
    "fee": "0", //手续费
    "timestamp": "1690070400" //起始时间
  }
  const { data = defaultValue } = useSWR(
    url,
    async (url) => {
      const res = await fetch(url);
      const json = await res.json();
      return json
    },
    {
      refreshInterval: 60000,
    }
  );

  return data;
}