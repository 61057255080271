import React, { useMemo, useState } from "react";
import { useCopyToClipboard } from "react-use";
import { BiCopy } from "react-icons/bi";
import ImgLevelTower from "img/referrals/level-tower.svg";
import ImgLevelTowerM from "img/referrals/level-tower-m.svg";
import { QRCodeSVG } from "qrcode.react";
import "./TierContent.css";
import { formatBigAmount, numberWithCommas } from "lib/numbers";
import { requestPost } from "lib/api";
import { useRequest } from "ahooks";
import { useChainId } from "lib/chains";
import { helperToast } from "lib/helperToast";
import { getServerBaseUrl, PageNameType } from "config/backend";
import useMobile from "hooks/useMobile";
import PositionShare from "components/Exchange/PositionShare";
import ImgShareBg from "img/referrals/share-twitter-bg.png";
import { getHomeUrl } from "lib/legacy";
import { Trans } from "@lingui/macro";

import ImgTower from "img/referrals/tower_tier.svg";
import ImgTower0 from "img/referrals/tower_tier_0.svg";
import ImgTower1 from "img/referrals/tower_tier_1.svg";
import ImgTower2 from "img/referrals/tower_tier_2.svg";
import ImgTower3 from "img/referrals/tower_tier_3.svg";
import ImgTower4 from "img/referrals/tower_tier_4.svg";
import ImgTower5 from "img/referrals/tower_tier_5.svg";
import ImgTower6 from "img/referrals/tower_tier_6.svg";
import ImgTower7 from "img/referrals/tower_tier_7.svg";
import { PollingInterval } from 'config/utils';

const MockTierMap = {
  0: { title: 'Common', activeTradersReferred: '-', totalTradingVolume: '-', tower: ImgTower0, },
  1: { title: 'Tier 1', activeTradersReferred: 10, totalTradingVolume: 1000, tower: ImgTower1, },
  2: { title: 'Tier 2', activeTradersReferred: 15, totalTradingVolume: 2000, tower: ImgTower2, },
  3: { title: 'Tier 3', activeTradersReferred: 20, totalTradingVolume: 5000, tower: ImgTower3, },
  4: { title: 'Tier 4', activeTradersReferred: 25, totalTradingVolume: 8000, tower: ImgTower4, },
  5: { title: 'Tier 5', activeTradersReferred: 30, totalTradingVolume: 10000, tower: ImgTower5, },
  6: { title: 'Tier 6', activeTradersReferred: 35, totalTradingVolume: 15000, tower: ImgTower6, },
  7: { title: 'Tier 7', activeTradersReferred: 50, totalTradingVolume: 20000, tower: ImgTower7, },
}
  
const mockDaya = {
  totalTradersReferred: 0, totalTradingVolume: 0, subList: []
};
export default function TierContent(props) {
  const { referralCode = "", ownersCode = "", userInfo, checkSummedAccount } = props;

  const { isMobile } = useMobile();
  const [, copyToClipboard] = useCopyToClipboard();
  const { chainId } = useChainId();
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [TierMap, setTierMap] = useState(MockTierMap)

  const { title, towerImg } = useMemo(() => {
    if (!userInfo || !TierMap[userInfo.tier]) {
      return {
        title: '-',
        towerImg: ImgTower,
      }
    }
    const { tier } = userInfo
    return {
      title: TierMap[tier] ? TierMap[tier]['title'] : '-',
      towerImg: TierMap[userInfo.tier] ? TierMap[userInfo.tier]['tower'] : ImgTower,
    }
  }, [userInfo])

  const BaseUrl = `${getServerBaseUrl(chainId, PageNameType.REFERRALS)}`;

  // 后端-获取下线列表
  const { data = { data: mockDaya } } = useRequest(
    async () => {
      if (!ownersCode) {
        return mockDaya;
      }
      return requestPost(
        `${BaseUrl}/referrals/user/sub`,
        {
          pageNum: 1,
          pageSize: 10,
        },
      );
    },
    {
      refreshDeps: [checkSummedAccount, ownersCode, referralCode],
      manual: false,
      pollingInterval: PollingInterval,
      onError: (error) => {
        console.error("Error fetching data-sub", error);
      },
    }
  );

  // 后端-获得等级规则
  useRequest(
    async () => {
      return requestPost(`${BaseUrl}/referrals/getTierMap`, {});
    },
    {
      manual: false,
      refreshDeps: [checkSummedAccount, ownersCode, referralCode],
      pollingInterval: PollingInterval,
      onSuccess: (data) => {
        if (data && data.code === 0) {
          const tierMap = data.data;
          let temp = {...TierMap}
          Object.keys(tierMap).forEach((key) => {
            temp[key] = {
              ...temp[key],
              ...tierMap[key],
            }
          })
          setTierMap(temp)
        }
      },
    }
  );

  const renderHead = useMemo(() => {
    const { needReferral,needTradingVolume} = userInfo
    return (
      <div className="header-container">
        <div className="left">
          <div className="box-top">
            <div className="my-level">{title}</div>
          </div>
          <div className="box-bottom">
            <Trans>
              To reach the next level, you need to invite {needReferral} active users and achieve a trading volume of ${formatBigAmount(needTradingVolume)}
            </Trans>
          </div>
        </div>
        {ownersCode && (
          <div className="right">
            <div className="share-code-container">
              <span className="share-code">{ownersCode}</span>
              <div
                onClick={() => {
                  copyToClipboard(ownersCode);
                  helperToast.success("Referral code copied to your clipboard");
                }}
                className="copy-button"
              >
                <BiCopy />
              </div>
            </div>
            <div className="share-buton" onClick={() => setShareModalVisible(true)}>
              <Trans>Share</Trans>
            </div>
          </div>
        )}
      </div>
    );
  }, [ownersCode, copyToClipboard, userInfo]);

  const renderSubList = useMemo(() => {
    if (!data || !data.data) {
      return null;
    }
    let content = null;
    const { subList = [] } = data.data;
    const [tier0, tier1, tier2, tier3, tier4, tier5, tier6, tier7] = subList;
    if (isMobile) {
      const renderRow = (rowData) => (
        <div className="common-container tradersReferred-container">
          <div className="item title">{rowData.name}</div>
          <div className="item">{numberWithCommas(rowData.tradersReferred)}</div>
          <div className="item">{rowData.tradingVolume && '$'}{formatBigAmount(rowData.tradingVolume)}</div>
        </div>
      );
      content = (
        <div className="footer-container">
          <div className="common-container title-container">
            <div className="item"></div>
            <div className="item">
              <Trans>Traders Referred</Trans>
            </div>
            <div className="item">
              <Trans>Total Trading Volume</Trans>
            </div>
          </div>
          {renderRow({ ...tier0, name: "CU." })}
          {renderRow({ ...tier1, name: "Tier 1" })}
          {renderRow({ ...tier2, name: "Tier 2" })}
          {renderRow({ ...tier3, name: "Tier 3" })}
          {renderRow({ ...tier4, name: "Tier 4" })}
          {renderRow({ ...tier5, name: "Tier 5" })}
          {renderRow({ ...tier6, name: "Tier 6" })}
          {renderRow({ ...tier7, name: "Tier 7" })}
        </div>
      );
    } else {
      content = (
        <div className="footer-container">
          <div className="common-container title-container">
            <div className="type"></div>
            <div className="item">CU.</div>
            {<div className="item">Tire 1</div>}
            {<div className="item">Tire 2</div>}
            {<div className="item">Tire 3</div>}
            {<div className="item">Tire 4</div>}
            {<div className="item">Tire 5</div>}
            {<div className="item">Tire 6</div>}
            {<div className="item">Tire 7</div>}
          </div>
          <div className="common-container tradersReferred-container">
            <div className="type">
              <Trans>Traders Referred</Trans>
            </div>
            {<div className="item">{numberWithCommas(tier0?.tradersReferred)}</div>}
            {<div className="item">{numberWithCommas(tier1?.tradersReferred)}</div>}
            {<div className="item">{numberWithCommas(tier2?.tradersReferred)}</div>}
            {<div className="item">{numberWithCommas(tier3?.tradersReferred)}</div>}
            {<div className="item">{numberWithCommas(tier4?.tradersReferred)}</div>}
            {<div className="item">{numberWithCommas(tier5?.tradersReferred)}</div>}
            {<div className="item">{numberWithCommas(tier6?.tradersReferred)}</div>}
            {<div className="item">{numberWithCommas(tier7?.tradersReferred)}</div>}
          </div>
          <div className="common-container tradingVolume-container">
            <div className="type">
              <Trans>Total Trading Volume</Trans>
            </div>
            {<div className="item">{tier0 && '$'}{formatBigAmount(tier0?.tradingVolume)}</div>}
            {<div className="item">{tier1 && '$'}{formatBigAmount(tier1?.tradingVolume)}</div>}
            {<div className="item">{tier2 && '$'}{formatBigAmount(tier2?.tradingVolume)}</div>}
            {<div className="item">{tier3 && '$'}{formatBigAmount(tier3?.tradingVolume)}</div>}
            {<div className="item">{tier4 && '$'}{formatBigAmount(tier4?.tradingVolume)}</div>}
            {<div className="item">{tier5 && '$'}{formatBigAmount(tier5?.tradingVolume)}</div>}
            {<div className="item">{tier6 && '$'}{formatBigAmount(tier6?.tradingVolume)}</div>}
            {<div className="item">{tier7 && '$'}{formatBigAmount(tier7?.tradingVolume)}</div>}
          </div>
        </div>
      );
    }
    return content;
  }, [data, isMobile]);

  const homeURL = getHomeUrl();
  const { tradersReferred, myTradingVolume } = userInfo;
  const ShareContent = (
    <div className="share-container">
      <div className="data-info-list">
        <div className="data-info-container" style={{ flex: 1 }}>
          <div className="data-value">{tradersReferred}</div>
          <div className="data-title">Users</div>
        </div>
        <div className="data-info-container" style={{ flex: 2, borderLeft: "1px solid #9D9D9D" }}>
          <div className="data-value">${formatBigAmount(myTradingVolume)}</div>
          <div className="data-title">
            <Trans>My Total Trading Volume</Trans>
          </div>
        </div>
      </div>
      <div className="bottom-content">
        <div className="invition-code-container">
          <div className="code-title">
            <Trans>Invitation code</Trans>
          </div>
          <div className="code">{ownersCode}</div>
        </div>
        <div className="qr-code-container">
          <div className="url-text">https://Syncperp.com</div>
          <QRCodeSVG size={isMobile ? 24 : 32} value={`${homeURL}/#/?ref=${ownersCode}`} />
        </div>
      </div>
    </div>
  );
  return (
    <React.Fragment>
      <PositionShare
        setIsPositionShareModalOpen={setShareModalVisible}
        isPositionShareModalOpen={shareModalVisible}
        positionToShare={{ indexToken: {} }}
        chainId={chainId}
        account={checkSummedAccount}
        modalTitle={"Share"}
        shareBackgroundImgage={ImgShareBg}
        customerContent={ShareContent}
      />
      <div className="Exchange-list Orders App-box level-container">
        {renderHead}
        <div className="body-container">
          <div className="body-wrapper">
            <div className="tower-container">
              <img src={towerImg} className="img" alt="temp" />
            </div>
            <div className="rule-container">
            <div className="rule-row rule-title">
              <div className="rule-item"><Trans>Active Users</Trans></div>
              <div className="rule-item "><Trans>Trading Volume</Trans></div>
            </div>
            <ul className={`rule-row ${userInfo &&  userInfo.tier >= 7 ?'active-row':''}`}>
              <li className="rule-item">{TierMap[7]['activeTradersReferred']} Users</li>
              <li className="rule-item">${TierMap[7]['totalTradingVolume']} </li>
            </ul>
            <ul className={`rule-row ${userInfo &&  userInfo.tier >= 6 ?'active-row':''}`}>
              <li className="rule-item">{TierMap[6]['activeTradersReferred']} Users</li>
              <li className="rule-item">${TierMap[6]['totalTradingVolume']} </li>
            </ul>
            <ul className={`rule-row ${userInfo &&  userInfo.tier >= 5 ?'active-row':''}`}>
              <li className="rule-item">{TierMap[5]['activeTradersReferred']} Users</li>
              <li className="rule-item">${TierMap[5]['totalTradingVolume']} </li>
            </ul>
            <ul className={`rule-row ${userInfo &&  userInfo.tier >= 4 ?'active-row':''}`}>
              <li className="rule-item">{TierMap[4]['activeTradersReferred']} Users</li>
              <li className="rule-item">${TierMap[4]['totalTradingVolume']} </li>
            </ul>
            <ul className={`rule-row ${userInfo && userInfo.tier >= 3 ? 'active-row' : ''}`}>
              <li className="rule-item">{TierMap[3]['activeTradersReferred']} Users</li>
              <li className="rule-item">${TierMap[3]['totalTradingVolume']}</li>
            </ul>
            <ul className={`rule-row ${userInfo &&  userInfo.tier >= 2 ?'active-row':''}`}>
              <li className="rule-item">{TierMap[2]['activeTradersReferred']} Users</li>
              <li className="rule-item">${TierMap[2]['totalTradingVolume']} </li>
            </ul>
            <ul className={`rule-row ${userInfo &&  userInfo.tier >= 1 ?'active-row':''}`}>
              <li className="rule-item">{TierMap[1]['activeTradersReferred']} Users</li>
              <li className="rule-item">${TierMap[1]['totalTradingVolume']} </li>
            </ul>
            <ul className={`rule-row ${userInfo &&  userInfo.tier >= 0 ?'active-row':''}`}>
              <li className="rule-item">-</li>
              <li className="rule-item">-</li>
            </ul>
            </div>
          </div>
        </div>
        {renderSubList}
      </div>
    </React.Fragment>
  );
}
