import { Trans } from "@lingui/macro";
import Tooltip from "components/Tooltip/Tooltip";
import {
  USD_DECIMALS,
} from "lib/legacy";
import { formatAmount } from 'lib/numbers';

export default function DataCard(props) {
  const {
    title,
    value,
    renderContent,
    decimals = USD_DECIMALS,
    handleValue = (v) => formatAmount(v, decimals, 0, true),
    prefix = '$'
  } = props;

  return (<div className="data-card">
    <div className="label">
      {typeof (title) == 'string'
        ? <Trans>{title}</Trans>
        : title
      }
    </div>
    <div className='value-container'>
      {renderContent
        ? <Tooltip
        handle={`$${handleValue(value)}`}
        position="left-bottom"
        renderContent={renderContent}
        />
        : `${prefix}${handleValue(value)}`}
    </div>
  </div>)
}