import { getServerBaseUrl } from 'config/backend'
import { getSignatureHeader } from 'utils';

const onFetch = async (url, method, params, headers) => {
  const requestConfig: any = {
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      "Content-type": "application/json; charset=UTF-8",
      ...getSignatureHeader(),
      ...headers,
    },
    // mode: "cors",
    // cache: "force-cache",
    method,
  }

  if (method === 'GET') {
    let dataStr = ''; //数据拼接字符串
    Object.keys(params).forEach(key => {
      dataStr += key + '=' + params[key] + '&';
    })
    if (dataStr !== '') {
      dataStr = dataStr.substr(0, dataStr.lastIndexOf('&'));
      url = url + '?' + dataStr;
    }
  }
  if (method === 'POST') {
    Object.defineProperty(requestConfig, 'body', {
      value: JSON.stringify(params)
    })
  }
  try {
    const response = await fetch(url, requestConfig);
    const responseJson = await response.json();
    return responseJson
  } catch (error) {
    throw new Error(error)
  }
}
const requestGet = (url: string, params, headers = {}) => {
  return onFetch(url, 'GET', params, headers)
}

const requestPost = (url: string, params, headers = {}) => {
  return onFetch(url, 'POST', params, headers)
}

export {
  requestGet,
  requestPost,
}
