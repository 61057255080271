import React, { useEffect, useState } from "react";
import { Trans, t } from "@lingui/macro";
import Modal from "components/Modal/Modal";
import ReferralStorage from 'abis/ReferralStorage.json'
import { ethers } from "ethers";
import { getContract } from "config/contracts";

// import "../StakeV2.css";
import Button from "components/Button/Button";
import { useRequest } from 'ahooks';
import { callContract } from 'lib/contracts';
import { formatBytes32String } from 'lib/format-byte32-string';
import { useDebounce } from 'lib/useDebounce';
import { REFERRAL_CODE_REGEX } from 'components/Referrals/referralsHelper';
import { validateReferralCodeExists } from 'domain/referrals';

export default function InfoBindCodeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    library,
    refreshData,
  } = props;
  const [userInputCode, setuserInputCode] = useState('')
  const [isValidating, setIsValidating] = useState(false);
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const [referralCodeExists, setReferralCodeExists] = useState(true);
  const debouncedReferralCode = useDebounce(userInputCode, 300);
  const [isInValidateCode, setIsInValidateCode] = useState(false)

  const { loading: isSubmitting = false, run: bindReferralCode } = useRequest(() => {
    const address = getContract(chainId, "ReferralStorage");
    const contract = new ethers.Contract(address, ReferralStorage.abi, library.getSigner());
    return callContract(chainId, contract, "setTraderReferralCodeByUser", [formatBytes32String(debouncedReferralCode)], {
      successMsg: `Referral code Saved!`,
      failMsg: `Binding referral code failed.`,
    })
  }, {
    manual: true,
    onSuccess: (data) => {
      // console.log('chf-success-bindReferralCode ', data)
      refreshData()
    },
    onError: (error) => {
      // console.log('chf-error-bindReferralCode', error)
    },
    onFinally: () => {
      setIsVisible(false)
    }
  });

  const getPrimaryText = () => {
    if (isSubmitting) {
      return t`Binding...`;
    }
    if (debouncedReferralCode === "") {
      return t`Enter Referral Code`;
    }
    if (isValidating) {
      return t`Checking code...`;
    }
    if (!referralCodeExists) {
      return t`Referral Code does not exist`;
    }

    return t`Bind`;
  }

  const isPrimaryEnabled = () => {
    if (
      debouncedReferralCode === "" ||
      isSubmitting ||
      isValidating ||
      !referralCodeExists
    ) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    let cancelled = false;
    async function checkReferralCode() {
      if (debouncedReferralCode === "") {
        setIsValidating(false);
        setReferralCodeExists(false);
        return;
      }

      if (!REFERRAL_CODE_REGEX.test(debouncedReferralCode)) {
        setIsValidating(false);
        setIsInValidateCode(true);
        return;
      }

      setIsInValidateCode(false);
      setIsValidating(true);
      const codeExists = await validateReferralCodeExists(debouncedReferralCode, chainId);
      if (!cancelled) {
        setReferralCodeExists(codeExists);
        setIsValidating(false);
      }
    }
    checkReferralCode();
    return () => {
      cancelled = true;
    };
  }, [debouncedReferralCode, chainId]);

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={t`Bind Invitation Code`}>
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-bottom">
            <input
              type="text"
              placeholder="Enter a code"
              className="Exchange-swap-input"
              value={userInputCode}
              onChange={(e) => setuserInputCode(e.target.value)}
              autoFocus={true}
            />
          </div>
        </div>
        {debouncedReferralCode && isInValidateCode && <div style={{ color: 'red', position: 'relative', top: '-6px' }}>Only number, string and underscore is allowed</div>}
        <div className="Exchange-swap-button-container">
          <Button
            variant="primary-action"
            className="w-100"
            onClick={() => bindReferralCode()}
            disabled={!isPrimaryEnabled()}
          >
            {getPrimaryText()}
          </Button>
        </div>
      </Modal>
    </div>
  );
}
