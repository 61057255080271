import { useRequest } from 'ahooks';
import { getServerBaseUrl } from 'config/backend';
import { getContract } from 'config/contracts';
import { ethers } from 'ethers';
import { requestGet } from 'lib/api';

import MDCA from 'abis/MDCA.json'
import { callContract } from 'lib/contracts';
import { PollingInterval } from 'config/utils';
import { expandDecimals } from 'lib/numbers';
import { MDCA_DECIMALS } from 'lib/legacy';
import { getUserContract } from 'utils';

export function useMdcaPrice(chainId: number, library, account) {
  const defaultValuePrice = expandDecimals(1, MDCA_DECIMALS)
  const defaultValue = { platformTokenPrices: defaultValuePrice }
  const EarnBaseUrl = `${getServerBaseUrl(chainId)}`;

  const { data = defaultValue } = useRequest(
    () => {
      return requestGet(`${EarnBaseUrl}/dashboard`, {})
    }, {
    refreshDeps: [library],
    pollingInterval: PollingInterval,
  });

  return {
    platformTokenPrices: expandDecimals(data.platformTokenPrices, MDCA_DECIMALS)
  }
}

export function useMdcaTotalSupply(chainId: number, library, account) {
  const defaultValue = expandDecimals(0, MDCA_DECIMALS)
  const { data: mdcaTotalSupply = defaultValue } = useRequest(() => {
    const contract = getUserContract(chainId, library, "MDCA", MDCA.abi)
    return callContract(chainId, contract, "totalSupply", [], {
      hideSuccessMsg: true,
      hideErrorMsg: true,
    })
  }, {
    refreshDeps: [library],
    pollingInterval: PollingInterval,
    onSuccess: (data) => {
      // console.log('chf-success-useMdcaTotalSupply ', data.toString())
    },
    onError: (data) => {
      // console.log('chf-error-useMdcaTotalSupply ', data)
    },

  });
  return { mdcaTotalSupply }
}
export function useMdcaTotalStaked(chainId: number, library, account) {
  const defaultValue = expandDecimals(0, MDCA_DECIMALS)
  const { data: mdcaTotalStaked = defaultValue } = useRequest(() => {
    const contract = getUserContract(chainId, library, "MDCA", MDCA.abi)
    return callContract(chainId, contract, "totalStaked", [], {
      hideSuccessMsg: true,
      hideErrorMsg: true,
    })
  }, {
    refreshDeps: [library],
    pollingInterval: PollingInterval,
  });
  return { mdcaTotalStaked }
}