import { BigNumber, BigNumberish, ethers } from "ethers";
import { MDCA_DECIMALS } from './legacy';

export function bigNumberify(n: BigNumberish = 0) {
  try {
    return BigNumber.from(n);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("bigNumberify error", e);
    return undefined;
  }
}

export function expandDecimals(n: BigNumberish, decimals: number): BigNumber {
  // @ts-ignore
  return bigNumberify(n).mul(bigNumberify(10).pow(decimals));
}

export const trimZeroDecimals = (amount: string) => {
  if (parseFloat(amount) === parseInt(amount)) {
    return parseInt(amount).toString();
  }
  return amount;
};

export const limitDecimals = (amount: BigNumberish, maxDecimals?: number) => {
  let amountStr = amount.toString();
  if (maxDecimals === undefined) {
    return amountStr;
  }
  if (maxDecimals === 0) {
    return amountStr.split(".")[0];
  }
  const dotIndex = amountStr.indexOf(".");
  if (dotIndex !== -1) {
    let decimals = amountStr.length - dotIndex - 1;
    if (decimals > maxDecimals) {
      amountStr = amountStr.substr(0, amountStr.length - (decimals - maxDecimals));
    }
  }
  return amountStr;
};

export const padDecimals = (amount: BigNumberish, minDecimals: number) => {
  let amountStr = amount.toString();
  const dotIndex = amountStr.indexOf(".");
  if (dotIndex !== -1) {
    const decimals = amountStr.length - dotIndex - 1;
    if (decimals < minDecimals) {
      amountStr = amountStr.padEnd(amountStr.length + (minDecimals - decimals), "0");
    }
  } else {
    amountStr = amountStr + ".0000";
  }
  return amountStr;
};

export const formatAmount = (
  amount: BigNumberish | undefined,
  tokenDecimals: number,
  displayDecimals?: number,
  useCommas?: boolean,
  defaultValue?: string
) => {
  if (!defaultValue) {
    defaultValue = "...";
  }
  if (amount === undefined || amount.toString().length === 0) {
    return defaultValue;
  }
  if (displayDecimals === undefined) {
    displayDecimals = 4;
  }
  let amountStr = ethers.utils.formatUnits(amount, tokenDecimals);
  amountStr = limitDecimals(amountStr, displayDecimals);
  if (displayDecimals !== 0) {
    amountStr = padDecimals(amountStr, displayDecimals);
  }
  if (useCommas) {
    return numberWithCommas(amountStr);
  }
  return amountStr;
};

export const formatBigAmount = (
  amount: BigNumberish | undefined,
  tokenDecimals: number = MDCA_DECIMALS,
  displayDecimals: number = 0,
  defaultValue?: string,
) => {
  if (!defaultValue) {
    defaultValue = "-";
  }
  if (amount === undefined || amount.toString().length === 0) {
    return defaultValue;
  }

  let showMillionBillion = '';
  let amountStr = ethers.utils.formatUnits(amount, tokenDecimals);
  // amountStr = (1 * Number(amountStr) * Number(amountStr) * Number(amountStr)).toString()
  // 当数字大于 1000 * 1000000的时候折算Million
  if (Number(amountStr) > 1000 * 1000 * 1000 * 1000 * 1000000) {
    // 当数字大于 1000 * 1000 * 1000000的时候折算Million
    showMillionBillion = 'Q';
    amountStr = (Number(amountStr) / 1000 / 1000 / 1000 / 1000000).toString()
  } else if (Number(amountStr) > 1000 * 1000 * 1000 * 1000000) {
    // 当数字大于 1000 * 1000 * 1000000的时候折算Million
    showMillionBillion = 'T';
    amountStr = (Number(amountStr) / 1000 / 1000 / 1000000).toString()
  } else if (Number(amountStr) > 1000 * 1000 * 1000000) {
    // 当数字大于 1000 * 1000 * 1000000的时候折算Million
    showMillionBillion = 'B';
    amountStr = (Number(amountStr) / 1000 / 1000000).toString()
  } else if (Number(amountStr) > 1000 * 1000000) {
    // 当数字大于 1000 * 1000000的时候折算Million
    showMillionBillion = 'M';
    amountStr = (Number(amountStr) / 1000000).toString()
  }
  amountStr = limitDecimals(amountStr, displayDecimals);
  if (displayDecimals !== 0) {
    amountStr = padDecimals(amountStr, displayDecimals);
  }
  return `${numberWithCommas(amountStr)}${showMillionBillion}`;
};

export const formatKeyAmount = (
  map: any,
  key: string,
  tokenDecimals: number,
  displayDecimals: number,
  useCommas?: boolean
) => {
  if (!map || !map[key]) {
    return "...";
  }

  return formatAmount(map[key], tokenDecimals, displayDecimals, useCommas);
};

export const formatArrayAmount = (
  arr: any[],
  index: number,
  tokenDecimals: number,
  displayDecimals?: number,
  useCommas?: boolean
) => {
  if (!arr || !arr[index]) {
    return "...";
  }

  return formatAmount(arr[index], tokenDecimals, displayDecimals, useCommas);
};

export const formatAmountFree = (amount: BigNumberish, tokenDecimals: number, displayDecimals?: number) => {
  if (!amount) {
    return "...";
  }
  let amountStr = ethers.utils.formatUnits(amount, tokenDecimals);
  amountStr = limitDecimals(amountStr, displayDecimals);
  return trimZeroDecimals(amountStr);
};

export const parseValue = (value: string, tokenDecimals: number) => {
  const pValue = parseFloat(value);

  if (isNaN(pValue)) {
    return undefined;
  }

  value = limitDecimals(value, tokenDecimals);
  const amount = ethers.utils.parseUnits(value, tokenDecimals);
  return bigNumberify(amount);
};

export function numberWithCommas(x: BigNumberish) {
  if (!x) {
    return "-";
  }

  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function truncateNumber(value) {
  if (!value) return;
  const stringValue = String(value);
  const decimalIndex = stringValue.indexOf('.');

  let truncatedValue;
  if (decimalIndex !== -1) {
    // 处理有小数的情况
    const integerPart = stringValue.slice(0, decimalIndex);
    const decimalPart = stringValue.slice(decimalIndex + 1);

    if (integerPart.length > 20) {
      truncatedValue = integerPart.slice(0, 20) + '.' + decimalPart;
    } else {
      truncatedValue = value;
    }
  } else {
    // 处理整数的情况
    if (stringValue.length > 17) {
      truncatedValue = stringValue.slice(0, 17);
    } else {
      truncatedValue = value;
    }
  }

  return truncatedValue;
}
