import "./Referrals.css";
import "./Referrals-m.css";
import SEO from "components/Common/SEO";
import Footer from "components/Footer/Footer";
import { getPageTitle } from "lib/legacy";
import TierContent from "./components/TierContent";
import DetailHistoryContent from "./components/DetailHistoryContent";
import InfoContent from "./components/InfoContent";
import { ethers } from "ethers";
import { useRequest } from "ahooks";
import { useChainId } from "lib/chains";
import { getContract } from "config/contracts";
import ReferralStorage from "abis/ReferralStorage.json";
import { callContract } from "lib/contracts";
import { useWeb3React } from "@web3-react/core";
import { EmptyBytes32String, parseBytes32String } from "lib/format-byte32-string";
import { PageNameType, getServerBaseUrl } from "config/backend";
import { requestPost } from "lib/api";
import { t, Trans } from "@lingui/macro";
import { useState } from 'react';
import { PollingInterval } from 'config/utils';

const MockUserInfo = {
  totalFee: "0",
  //总计返佣费用 ETH计价，单位wei（total Rebates）
  discountFee: "0",
  //累计交易折扣 ETH计价，单位wei
  commissionFee: "0",
  //累计交易返佣费用 ETH计价，单位wei
  claimFee: "0",
  //已经领取费用,ETH计价，单位wei（claimable）
  tradersReferred: "0",
  //下线总用户数 下线活跃用户数
  activeTradersReferred: "0",
  //下线活跃用户数
  myTradingVolume: "0",
  //我的交易额，U计价，单位wei
  totalTradingVolume: "0",
  //总交易额，U计价，单位wei
  tier: -1,
  //我的等级,
  discountRate: 0,
  commissionRate: 0,
};

function Referrals({ connectWallet, setPendingTxns, pendingTxns }) {
  const { account, library, provider } = useWeb3React();
  const { chainId } = useChainId();

  let checkSummedAccount = ethers.utils.isAddress(account) ? ethers.utils.getAddress(account) : "";

  // 查询用户的绑定邀请码，和邀请人钱包地址
  const { data: [referralCode] = [EmptyBytes32String], run: getReferralCode } = useRequest(
    () => {
      if (!checkSummedAccount || !library) {
        return [EmptyBytes32String];
      }
      const address = getContract(chainId, "ReferralStorage");
      const contract = new ethers.Contract(address, ReferralStorage.abi, library.getSigner());
      return callContract(chainId, contract, "getTraderReferralInfo", [checkSummedAccount], {
        hideSuccessMsg: true,
        hideErrorMsg: true,
      });
    },
    {
      // manual: true,
      refreshDeps: [checkSummedAccount, library],
      // onSuccess: (data) => {
      //   console.log("chf-success-getTraderReferralInfo ", data);
      // },
      // onError: (error) => {
      //   console.log("chf-error-getTraderReferralInfo", error);
      // },
    }
  );

  // 查询创建的邀请码
  const { data: ownersCode = EmptyBytes32String, run: getOwenerCode } = useRequest(
    () => {
      if (!checkSummedAccount || !library) {
        return EmptyBytes32String;
      }
      const address = getContract(chainId, "ReferralStorage");
      const contract = new ethers.Contract(address, ReferralStorage.abi, library.getSigner());
      return callContract(chainId, contract, "ownersCode", [checkSummedAccount], {
        hideSuccessMsg: true,
        hideErrorMsg: true,
      });
    },
    {
      // manual: true,
      refreshDeps: [checkSummedAccount, library],
      // onSuccess: (data) => {
      //   console.log("chf-success-ownersCode ", data);
      // },
      // onError: (error) => {
      //   console.log("chf-error-ownersCode", error);
      // },
    }
  );

  // 后端-查询用户数据
  const BaseUrl = `${getServerBaseUrl(chainId, PageNameType.REFERRALS)}`;
  const { data: { data: userInfo } = { data: MockUserInfo }, run: getUserInfo } = useRequest(
    async () => {
      if (!referralCode) {
        return { data: MockUserInfo };
      }
      return requestPost(`${BaseUrl}/referrals/user/info`, {});
    },
    {
      refreshDeps: [provider, checkSummedAccount, referralCode, ownersCode],
      manual: false,
      pollingInterval: PollingInterval,
      // onSuccess: (data) => {
      //   console.log("chf-success-userInfo", data);
      // },
      // onError: (data) => {
      //   console.log("chf-error-userInfo", data);
      // },
    }
  );

  // 后端-测试登陆接口
  // const { loading } = useRequest(
  //   async () => {
  //     return requestPost(`${BaseUrl}/referrals/user/testLogin`, {});
  //   },
  //   {
  //     refreshDeps: [provider, checkSummedAccount, referralCode, ownersCode],
  //     manual: false,
  //     // onSuccess: (data) => {
  //     //   console.log("chf-success-testLogin", data);
  //     // },
  //     // onError: (data) => {
  //     //   console.log("chf-error-testLogin", data);
  //     // },
  //   }
  // );

  const refreshData = (delay = 1000) => {
    const timer = setInterval(() => {
      getReferralCode();
      getOwenerCode();
      getUserInfo();
      if (referralCode !== EmptyBytes32String) {
        clearInterval(timer)
      }
    }, delay);
    setTimeout(() => {
      clearInterval(timer)
    },30 * 1000)
  };

  const referralCodeString = parseBytes32String(referralCode);
  const ownerCodeString = parseBytes32String(ownersCode);
  // // console.log('chf-111', `referralCode: -${referralCodeString}-`, `ownersCode:-${ownerCodeString}-`)
  return (
    <SEO title={getPageTitle("Referrals")}>
      <div className="default-container page-layout Referrals">
        <div className="referral-common-container referral-banner-container">
          <div className="referral-banner-h">
            <Trans>Syncperp Growth Plan</Trans>
          </div>
          <div className="referral-banner-p">
            <div>
              <Trans>Broker rebate up to 65%</Trans>
            </div>
            <div>
              <Trans>Traders can enjoy up to 70% rate reduction</Trans>
            </div>
          </div>
        </div>
        <div className="referral-common-container referral-account-container">
          <InfoContent
            userInfo={userInfo}
            referralCode={referralCodeString}
            ownersCode={ownerCodeString}
            getOwenerCode={getOwenerCode}
            refreshData={refreshData}
            BaseUrl={BaseUrl}
            checkSummedAccount={checkSummedAccount}
            connectWallet={connectWallet}
          />
        </div>
        <div className="referral-common-container eferral-tier-container">
          <TierContent
            userInfo={userInfo}
            referralCode={referralCodeString}
            ownersCode={ownerCodeString}
            checkSummedAccount={checkSummedAccount}
          />
        </div>
        <div className="referral-common-container referral-history-container">
          <div className="container-title">
            <Trans>Detail History</Trans>
          </div>
          <DetailHistoryContent referralCode={referralCodeString} />
        </div>
      </div>
      <Footer />
    </SEO>
  );
}

export default Referrals;
