import { ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, ZK_TESTNET } from "config/chains";
import { TOKENS_BY_SYMBOL_MAP } from "./tokens";

const oneInchTokensMap = {
  [ARBITRUM]: {
    BTC: "WBTC",
  },
  [AVALANCHE]: {
    BTC: "BTC.b",
    ETH: "WETH.e",
    WBTC: "WBTC.e",
  },
};

export const BuyMDCAInUniswapUrl = '/';
export const BuyMDCAInDoDoUrl = '/';
export const SecurityUrl = '/';

export function get1InchSwapUrl(chainId: number, from?: string, to?: string) {
  const rootUrl = `https://app.1inch.io/#/${chainId}/simple/swap`;
  const chainTokensMap = TOKENS_BY_SYMBOL_MAP[chainId];
  const isInvalidInput = !from || !to || !chainTokensMap[from] || !chainTokensMap[to];
  if (isInvalidInput) {
    return rootUrl;
  }
  const fromToken = oneInchTokensMap[chainId]?.[from] || from;
  const toToken = oneInchTokensMap[chainId]?.[to] || to;
  return `${rootUrl}/${fromToken}/${toToken}`;
}

export function getLeaderboardLink(chainId) {
  if (chainId === ARBITRUM) {
    return "https://www.gmx.house/arbitrum/leaderboard";
  }
  if (chainId === AVALANCHE) {
    return "https://www.gmx.house/avalanche/leaderboard";
  }
  return "https://www.gmx.house";
}

const STATS = {
  [ARBITRUM_TESTNET]: 'https://stats.mdca.io',
  [ZK_TESTNET]: 'https://stats.mdca.io'
}
export const getStatsLink = (chainId: string) => {
  if (!STATS[chainId]) {
    return '';
  }
  return STATS[chainId]
}

const HashDomain = {
  [ARBITRUM_TESTNET]: 'https://goerli.explorer.zksync.io/',
  [ZK_TESTNET]: 'https://goerli.explorer.zksync.io/'
}

export const getHashLink = (chainId: string) => {
  if (!HashDomain[chainId]) {
    return '';
  }
  return HashDomain[chainId]
}